import { useEffect, useState } from "react"
import { useSnapshot } from "valtio"
import Api from "../state/Api"
import Newapi from "../state/new-api"
import Utils from "../util/Utils"
import AgChoice from "../components/ag-choice"
import { enquiryStore } from "../enquiry/enquiry-data"
import { DestinationSwitch, OriginSwitch } from "../components/ag-switch"
import { rtpStore } from "./pricing-store"

export default function PricingAddress2(props) {
    const { label, record, name, callback = f => f, isValid = true, validationMsg, isDisabled } = props
    const [timer, setTimer] = useState(null)
    const imRecord = useSnapshot(record)
    const [list, setList] = useState([])
    const [isDoor, setDoor] = useState(false)
    const [toggleval, settoggleval] = useState('Port')
    const [selectVisible, setSelectVisible] = useState('hidden')
    const [addresstext, setAddresstext] = useState('')
    useSnapshot(enquiryStore)
    useSnapshot(rtpStore)

    useEffect(() => {
        if (label == 'Origin') record[name] = ''
    }, [enquiryStore.current.originType])
    useEffect(() => {
        if (label != 'Origin') record[name] = ''
    }, [enquiryStore.current.destinationType])
    useEffect(() => {
        if(addresstext && addresstext.length>2){
        const getData = setTimeout(() => {
            let s = encodeURI(addresstext)
            let url = `${process.env.REACT_APP_API_SERVER}/api/v1/location/fetchlocation/${s}`
            let loctype = enquiryStore.current.originType
            if (label != 'Origin') loctype = enquiryStore.current.destinationType
            if (loctype != 'Port') url = `${process.env.REACT_APP_API_SERVER}/api/v1/location/fetchaddress/${s}`
            let results
            (async () => {
                results = await Newapi.get(url)
                if (results.error)
                    setList([])
                else
                    setList(results)
                console.log('RESULTS=', results)
            })()
        }, 300)
        return () => clearTimeout(getData)
    }
    }, [addresstext])

    const setLocation = (s, pureset = false) => {
        record[name] = s
        setAddresstext(s)
    }
    let doorLabel = 'Door'
    if (true) {
        if (label == 'Origin') doorLabel = 'Ex-Works'
        else doorLabel = 'Door Delivery'
    }
    let Choicecomp = OriginSwitch
    if (label != 'Origin') Choicecomp = DestinationSwitch
    return (
        <div className="field"

        >
            <div className="columns p-0">
                <div className="column is-12 p-0 m-0" style={{ fontSize: '1.0rem', color: '#555', fontWeight: 'bold' }}>
                    {label}
                </div>
            </div>
            <div className="columns" style={{ alignItems: 'left' }}>
                <div className="column is-12 " style={{ alignItems: 'left' }}>
                    <Choicecomp {...props}/>
                </div>
            </div>
            <div className="columns"
            >
                <div className="column is-12 p-0">
                    <textarea rows='3' style={{ height: '3rem' }}
                        autoComplete="off"
                        onClick={() => setSelectVisible('hidden')}
                        onKeyDown={() => setSelectVisible('visible')}
                        {...props} value={imRecord[name]}
                        {...isDisabled}
                        onFocus={() => setSelectVisible('visible')}
                        onChange={(e) => {
                            setLocation(e.target.value)
                            callback(e.target.value)
                            if (e.target.value == 'distance') rtpStore.distanceButtonVisible = true
                            if (e.target.value == 'hidedistance') rtpStore.distanceButtonVisible = false
                        }}
                        className={isValid ? "input is-small" : "input is-small is-danger"} />
                    <div style={{ width: '100%', visibility: selectVisible }}>
                        <select style={{
                            width: '100%',
                            border: '1px solid white',
                            overflowWrap: 'break-word',
                            whiteSpace: 'normal'
                        }}
                            size={selectVisible == 'visible' ? 3 : 0}
                            value={imRecord[name]}
                            onChange={(e) => {
                                console.log('selected value=', e.target.value)
                                setLocation(e.target.value)
                                setSelectVisible('hidden')
                            }}>
                            <option value=''></option>
                            {
                                list.map(e => <option style={{
                                    overflowWrap: 'break-word',
                                    whiteSpace: 'normal',
                                    border: '1px solid lightgrey'
                                }} value={e}>{e}</option>)
                            }
                        </select>
                    </div>
                </div>
            </div>
        </div>
    )
}