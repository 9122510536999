import { useSnapshot } from "valtio"
import { configStore, contractsStore, entityStore, loginStore, bookingsStore } from "../state/CommonState"
import { $c, $cg, $cr, AgInput, AgSelect } from '../components/AgComponents'
import EntityApi from "../state/entity-api"
import AguserApi from "../state/AguserApi"
import { useEffect, useState } from "react"
import Api from "../state/Api"
import { Roles, ServerLists, incoTermsListAirPricing } from "../StaticData"
import stateList from "../static-data/states"
import AgFile, { AgCustomFile } from "../components/AgFile"
import BookingsApi from "../state/bookings-api"
import { incoTermsList, commodityList, hazardousList } from "../StaticData"
import Agswitch, { GenericSwitch } from "../components/ag-switch"
import { Agyesno } from "../components/Agyesno"
import BookingConfirmation from "../schedules/booking-confirmation"
import LCLPricingSchedule from "./lcl.schedule"
import { rtpStore } from "./pricing-store"
import AirPricingSchedule from "./air.schedule"
import FCLPricingSchedule from "./fcl.schedule"
import BranchApi from "../state/branch-api"
import EntityV2Api from "../state/entities-api"
import { isArray } from "lodash"

export default function Pricingcwf(props) {
    let { hideme, contract } = props
    const [space, setSpace] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [branches, setbranches] = useState([]);
    const [addressObj, setaddressObj] = useState({})
    const [entityData, setentityData] = useState({})
    const [hasToAutoPopulateEntityDetail, setHasToAutoPopulateEntityDetail] = useState(false);
    const [buttonClickedAtleastOnce, setbuttonClickedAtleastOnce] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);
    const [validIIPCertificate, setValidIIPCertificate] = useState(true)
    useSnapshot(loginStore)
    useSnapshot(bookingsStore)
    useSnapshot(contractsStore)
    useSnapshot(configStore)
    useSnapshot(entityStore)
    useSnapshot(rtpStore)
let pricingRec
    if (rtpStore.routeSubscript >= 0) pricingRec = rtpStore.records[rtpStore.routeSubscript]
    let brec = bookingsStore.current
    brec.entityId = pricingRec.dummyContract?.entityId
    const aguser = loginStore.userRec.aguserRec
useEffect(() => {
        rtpStore.doCaseType = 'Direct Master AWB'
        let brec = bookingsStore.current
        brec.entityId = pricingRec?.dummyContract?.entityId
        let entityId = pricingRec.dummyContract.entityId
        // let entityId = "CEN0665"
      BranchApi.getAllBranches(entityId, (data)=>
      {
        // if(loginStore.isClient()){
            
        // }
        console.log('bhn',data);
        if(isArray(data)){
            setbranches(data)
        }

      }
      )
    }, [])
    useEffect(() => {
        async function setbookingdata () {
        EntityApi.getList()
        brec.firstName = aguser.firstName
        brec.lastName = aguser.lastName
        brec.phone = aguser.phone
        brec.emailid = loginStore.email
        brec.email = loginStore.email
        brec.incoTerms = contractsStore.current.incoTerms
        brec.commodityType = contractsStore.current.commodityType
        brec.carrierMBL = 'No'
        //brec.company = brec.contract.gst
        console.log('GOT COMPANY AS ========', brec.company, entityStore.list.length)
        /*const ent = entityStore.list.filter(e1 => e1.gst === brec.company)
        if (ent.length > 0) {
            let entity = ent[0]
            console.log('entity found =', entity)
            setCompanyName(entity.entityName)
            brec.address = entity.address
            brec.country = entity.country
            brec.pin = entity.pinCode
        } else {
            setCompanyName(brec.contract.gst)
        }*/
        // const entity = entityStore.list.find(e1 => {
        //     return e1.gst === brec.contract.gst
        // })
        // brec.contract.entityId 
        let entityId = pricingRec.dummyContract.entityId
        let entity = await EntityV2Api.viewEntity(entityId, setentityData)
        console.log("entity" , entity);

        if (!entity) {
            // setCompanyName(brec.contract.gst);
            return;
        }
        let fromLoc = brec.contract.shipmentType.toLowerCase().includes('air') ?
            brec.contract.originAirport
            : brec.contract.portOfReceipt
        // let fpodCountry = brec.contract.finalPlaceOfDelivery.substring(brec.contract.portOfReceipt.indexOf(',')+1,brec.contract.portOfReceipt.indexOf("(")).trim();
        console.log("fromLoc" , fromLoc)
        if (fromLoc.toLowerCase().includes(entity.entityCountry.toLowerCase())) {
            setHasToAutoPopulateEntityDetail(true);
            // brec.company = brec.contract.gst
            brec.company = entity.entityName
            setCompanyName(entity.entityName)
            brec.branchId=entity.branch[0].branchId
            brec.address = entity.branch[0].branchAddresses[0].address
            brec.country = entity.branch[0].country
            brec.pin = entity.branch[0].branchAddresses[0].pincode
            setaddressObj(JSON.stringify(entity.branch[0].branchAddresses[0]))
        }
    }
    setbookingdata()
    }, [bookingsStore.current])
    const domain = loginStore.email.split('@')[1]

    let title = 'Carrier Web Form'
    useSnapshot(contractsStore)

    const entity1 = entityStore.entityRec
    const renderLink = (fileName) => {
        if (fileName && fileName.length > 3) {
            return (
                <a href={process.env.REACT_APP_API_SERVER + '/clientfiles/' + fileName} target="_blank">{fileName}</a>
            )
        } else return <></>
    }
    const filecat = '' + Date.now()
    const enableMBL = () => (contractsStore.current.shipmentType.indexOf('FCL') >= 0
        && contractsStore.current.shipmentScope.endsWith('ort')) ? {} : { disabled: 1 }
    let commList = commodityList
    if (brec.contract.hazardous === 'Yes') commList = hazardousList
    const stype = () => brec.contract.shipmentType.toUpperCase().substr(0, 3)
     
    const setBranchfn = (e)=> {
        brec.branchId = e.target.value
        brec.entityId = brec.contract.entityId
        brec.address=""
        brec.pin = ""
        setaddressObj({})
    }

       const setAddressFn = (r,i)=> {
        console.log(r,i);
        if(r.target.value !== ""){
            let data = JSON.parse(r.target.value)
            brec.address = data.address
            brec.pin = data.pincode|| data.zipcode
            setaddressObj(r.target.value)
        }
     
        }

        const onSubmit = () => {
            setbuttonClickedAtleastOnce(true)
             setButtonClicked(true);
             let hasError = false
             if (!brec.incoTerms || brec.incoTerms.length < 1) {
                 hasError = true
             } 
             if (!brec.commodityType || brec.commodityType.length < 1) {
                 hasError = true
             } 
             if (!brec.phone || brec.phone.length < 7) {
                 hasError = true
             } 
             if (brec.contract.hazardous === 'Yes') {
                 if (!brec.iipcert_filename || brec.iipcert_filename.length < 1) {
                     setValidIIPCertificate(false)
                     hasError = true
                 }else{
                     setValidIIPCertificate(true)
                 }
             }
             if (!brec.branchId || brec.branchId.length < 1) {
                 hasError= true
            
             } 
             if (!brec.address || brec.address.length < 1) {
                 hasError= true
             } 
         
             if (!brec.firstName || brec.firstName.length < 1) {
                hasError= true
             }
             if (!brec.lastName || brec.lastName.length < 1) {
                hasError= true  
             }
             if (!brec.emailid || brec.emailid.length < 1) {
                hasError= true 
             }
           
             if (!brec.company || brec.company.length < 1 
                 // || !brec.address || !brec.pin || !brec.country
             ) {
                hasError= true 
             }
             if (!brec.country || brec.country.length < 1 ) {
                hasError= true 
             }  
              if (!brec.pin || brec.pin.length < 1 ) {
                 // configStore.setModalMessage('Please enter all company details')
                //  if(entityData.entityCountry.toLowerCase() === 'india'){
                //      configStore.setModalMessage('Please Enter PinCode')
                //  }else {
                //      configStore.setModalMessage('Please Enter ZipCode')
                //  }
                hasError= true 
              }

                
            let filteredCharges = bookingsStore.current.contract.chargesList.filter(e => e.isIncluded == 'Yes')
            if (pricingRec &&
                pricingRec.doCaseType == 1 &&
                pricingRec.hasHawb &&
                pricingRec.hasAgent &&
                rtpStore.doCaseType == 'Direct Master AWB') {   
                bookingsStore.current.contract.quoteValue = bookingsStore.current.contract.quoteValue - pricingRec.hawbAmt - pricingRec.agentAmt
                bookingsStore.current.contract.quoteValueDeduction = 0
                filteredCharges = filteredCharges.filter(e => e.chargeDescription != pricingRec.hawbChargeHead && e.chargeDescription != pricingRec.agentChargeHead)
            } else {
                bookingsStore.current.contract.quoteValueDeduction = 0
            }
            if (hasError) {
                setButtonClicked(false);
                return
            }
            console.log("jukn" ,bookingsStore.current.contract?.entityId , bookingsStore.current?.entityId , bookingsStore.current.contract?.entityId?.length === 0 && bookingsStore.current?.entityId?.length > 0 )
            if((bookingsStore.current.contract?.entityId?.length === 0 ||bookingsStore.current.contract?.entityId === undefined )&& bookingsStore.current?.entityId?.length > 0 ){
                bookingsStore.current.contract.entityId =  bookingsStore.current.entityId
            }
            bookingsStore.current.contract.chargesList = [...filteredCharges]
            BookingsApi.saveBooking(true)
            hideme(false)
        }

        

        function checkSelectedBranch(){
            let selectedbranch = entityData.branch.filter((r,i)=>r.branchId === brec.branchId)
            return selectedbranch[0]
         //  entityData.branch
         }
    // bre.schedule is passed to LCLPricingSchedule as the schedule selected is set into
    // the bookingstore.current.schedule variable when clicking on Proceed to booking on
    // lcl.schedule.js
    return (
        <div style={{ fontSize: '0.8rem' }}>
{/* {
                pricingRec && <h1>POR={'' + pricingRec.doCaseType + '-' + pricingRec.hasMawb + ',' + pricingRec.hasHawb + ',' + pricingRec.hasAgent + ',' + pricingRec.mawbChargeHead + ',' + pricingRec.hawbChargeHead + ',' + pricingRec.agentChargeHead}</h1>
            } */}
            {
                props.activeProduct == 'LCL' &&
                <LCLPricingSchedule subscript={rtpStore.routeSubscript} data={brec.schedule} isDummy={brec.hasToShowDummySchedule}
                    contract={props.dummyContract} nobutton={true} />
            }
            {
                props.activeProduct == 'Air' &&
                <AirPricingSchedule subscript={rtpStore.routeSubscript}
                    data={brec.schedule}
                    isDummy={brec.hasToShowDummySchedule}
                    contract={props.dummyContract} nobutton={true} />
            }
            {
                props.activeProduct == 'FCL' &&
                <FCLPricingSchedule subscript={rtpStore.routeSubscript}
                    data={brec.schedule}
                    isDummy={brec.hasToShowDummySchedule}
                    contract={props.dummyContract} nobutton={true} />
            }
            <div className="columns">
                <$cr n='2'>First Name{space}</$cr>
                <$c n='3'>
                    <input className={`input is-small ${( !buttonClickedAtleastOnce || brec.firstName) ? '' : 'is-danger'}`} value={brec.firstName}
                        onChange={(e) => brec.firstName = e.target.value} />
                </$c>
                <$cr n='2'>Last Name</$cr>
                <$c n='3'>
                    <input className={`input is-small ${( !buttonClickedAtleastOnce || brec.lastName) ? '' : 'is-danger'}`} value={brec.lastName}
                        onChange={(e) => brec.lastName = e.target.value} />
                </$c>
            </div>
            
            <div className="columns">
                <$cr n='2'>Email ID</$cr>
                <$c n='3'>
                    <input className={`input is-small ${( !buttonClickedAtleastOnce || brec.emailid) ? '' : 'is-danger'}`} value={brec.email}
                        onChange={(e) => {
                            brec.email = e.target.value
                            brec.emailid = e.target.value
                        }} />
                </$c>
                <$cr n='2'>Contact Number</$cr>
                <$c n='3'>
                    <input 
                    className={`input is-small ${( !buttonClickedAtleastOnce || brec.phone.length > 6) ? '' : 'is-danger'}`}
                    value={brec.phone}
                        onChange={(e) => brec.phone = e.target.value}
                        onKeyDown={(e) =>
                            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                        }
                        onInput={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, '').slice(0, 15);
                        }} />
                </$c>
            </div>
            <div className="columns">
            <$cr n='2'>Branch{space}</$cr>
                <$c n='3'>
                <div className={`select wd_100 ${!buttonClickedAtleastOnce ||  brec.branchId ? '' : 'is-danger'}`}>
                    <select  className={`select is-fullwidth is-small ${!buttonClickedAtleastOnce || brec.branchId ? '' : 'is-danger'}`} value={brec.branchId} onChange={(e)=>setBranchfn(e)}
                         >
                           <option value=""  >Select Branch</option>
                            {
                                branches?.map((r,i) => {
                                    return (<option value={r.branchId}>{`${r.gst} - ${r?.state?.split("/")[0]}`}</option>)
                                })
                            }
                            {/* <option>hyh</option> */}
                         </select>
                         </div>
                </$c>

            </div>
            <div className="columns">
                <$cr n='2'>Shipper Name</$cr>
                <$c n='3'>
                    {/*<select className="select is-fullwidth is-small" value={brec.company}
                        onChange={(e) => {
                            brec.company = e.target.value
                            const ent = entityStore.list.filter(e1 => e1.gst === e.target.value)[0]
                            brec.address = ent.address
                            brec.country = ent.country
                            //setSpace(space===''?' ':'')
                            console.log('Address', ent.address, brec.address)
                        }} >
                        <option value=''></option>
                        {entityStore.list.map(e => <option value={e.gst}>{e.entityName} - {e.state}</option>)}
                    </select>*/}
                    {
                        hasToAutoPopulateEntityDetail
                            ? companyName
                            : (<textarea className={`textarea ${(!buttonClickedAtleastOnce ||  brec.company)? '' : 'is-danger'}`} value={brec.company}
                                onChange={(e) => brec.company = e.target.value} />)
                    }
                </$c>
                <$cr n='2'>Address</$cr>
                <$c n='3'>
                    {
                        (hasToAutoPopulateEntityDetail && entityData.branch.length > 0) ? 
                        <div className={` select wd_100 ${!buttonClickedAtleastOnce ||brec.address ? '' : 'is-danger'}`}>
                        <select className="wd_100 p-1 select is-fullwidth is-small" onChange={(r,i) => setAddressFn(r,i)} value = {addressObj} 
                        // brec.pin   = e.target.value 
                        // console.log(i)
                          >{<option  value=''>select address</option>}{checkSelectedBranch()?.branchAddresses?.map((r,i)=> <option key={i} value={JSON.stringify(r)} >{r.address}</option>)}</select></div>:  <textarea className={` textarea wd_100 ${!buttonClickedAtleastOnce ||brec.address ? '' : 'is-danger'}`} value={brec.address}
                                onChange={(e) => brec.address = e.target.value}/>
                          
                    }
                </$c>
            </div>
            <div className="columns is-narrow">
                <$cr n='2'>PIN/Zip Code</$cr>
                <$c n='3'>
                    {
                        hasToAutoPopulateEntityDetail ? brec.pin
                            : <input className={`input is-small ${(!buttonClickedAtleastOnce || brec.pin)? '' : 'is-danger'}`} value={brec.pin}
                                onChange={(e) => brec.pin = e.target.value} />
                    }
                </$c>
                <$cr n='2'>Country</$cr>
                <$c n='3'>
                    {
                        hasToAutoPopulateEntityDetail
                            ? brec.country
                            : <div class="control">
                                <div class={`select is-fullwidth is-small ${!buttonClickedAtleastOnce || brec.country ? '' : 'is-danger'}`}>
                                    <select  className={`select is-fullwidth is-small ${!buttonClickedAtleastOnce || brec.country ? '' : 'is-danger'}`} value={brec.country}
                                        onChange={(e) => {
                                            brec.country = e.target.value
                                        }} >
                                        {ServerLists.countryNameList.map(e => <option value={e}>{e}</option>)}
                                    </select>
                                </div>
                            </div>
                    }
                </$c>
            </div>
            <div className="columns">
                <$cr n='2'>Incoterms</$cr>
                <$c n='3'>
                <div className={`select ${!buttonClickedAtleastOnce ||brec.incoTerms ? '' : 'is-danger'}`}>
                    <select className="select is-fullwidth is-small" value={brec.incoTerms}
                        onChange={(e) => {
                            brec.incoTerms = e.target.value
                        }} >
                        <option value=''></option>
                        {
                            (brec.contract.shipmentType == 'Air') ?
                                incoTermsListAirPricing.map(e => <option value={e}>{e}</option>)
                                :
                                incoTermsList.map(e => <option value={e}>{e}</option>)
                        }
                    </select>
                </div>
                </$c>
                <$cr n='2'>Commodity Type</$cr>
                <$c n='3'>
                     {/* <div className={`input ${!buttonClickedAtleastOnce || brec.commodityType ? '' : 'is-danger'}`}> */}
                        <input defaultValue={brec.commodityType} list='commodities'
                            onBlur={(e) => brec.commodityType = e.target.value}
                            className={`input is-fullwidth is-small ${!buttonClickedAtleastOnce || brec.commodityType ? '' : 'is-danger'}`}
                            />
                        <datalist id='commodities'>
                            {
                                commList.map(e => <option value={e} />)
                            }
                        </datalist>
                        {/* </div> */}
                </$c>
            </div>
{
                (pricingRec && pricingRec.doCaseType == 1 &&
                    pricingRec.hasHawb &&
                    pricingRec.hasAgent) &&
                <div className="columns">
                    <$cr n='2'></$cr>
                    <$c n='6'>
                        <GenericSwitch store={rtpStore} property='doCaseType' leftValue='Direct Master AWB' rightValue='House AWB' />
                    </$c>
                </div>
            }
            <div className="columns is-narrow">
                <$cr n='2'>Special Instructions</$cr>
                <$c n='6'>
                    <textarea rows='3' style={{ fontSize: '0.8rem' }} className="textarea" value={brec.specialInstructions}
                        onChange={(e) => brec.specialInstructions = e.target.value} />
                </$c>
                <$c n='2'>

                </$c>
            </div>

            <div className="columns">
                <$c n='1'></$c>
                <$c n='9'>
                    <div className="table-container ">
                        <table className="table is-fullwidth is-bordered is-striped is-narrow is-hoverable ">
                            <thead>
                                <tr>
                                    <th style={{ width: '16rem' }}>Required Documents</th>
                                    <th>Document Uploaded</th>
                                    <th style={{ width: '12rem' }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr id="cwf5">
                                    <td>Commercial Invoice (Non-Mandatory)</td>
                                    <td>
                                        {brec.commercialInvoice_filename_list.map(renderLink).map(link => <>{link}<br /></>)}
                                    </td>
                                    <td>
                                        <AgCustomFile source='carrierwebform' cat={filecat} filetype='commercialInvoice' parent="cwf5" multi={true}
                                            callback={(filename) => brec.commercialInvoice_filename_list.push(filename)} withBtn={brec.commercialInvoice_filename_list.length > 0} />
                                    </td>
                                </tr>
                                <tr id="cwf6">
                                    <td>Packing List (Non-Mandatory)</td>
                                    <td>{brec.packingList_filename_list.map(renderLink).map(link => <>{link}<br /></>)}</td>
                                    <td>
                                        <AgCustomFile source='carrierwebform' cat={filecat} filetype='packingList' parent="cwf6" multi={true}
                                            callback={(filename) => brec.packingList_filename_list.push(filename)} withBtn={brec.packingList_filename_list.length > 0} />
                                    </td>
                                </tr>
                                {
                                    (
                                        (brec.commodityType && brec.commodityType.startsWith('Non-Hazardous Chemicals'))
                                        || (brec.contract.hazardous === 'Yes')
                                    ) ?
                                        <tr>
                                            <td>MSDS1</td>
                                            <td>{renderLink(brec.msds_filename)}</td>
                                            <td>
                                                <AgFile source='carrierwebform' cat={filecat} filetype='msds'
                                                    callback={(filename) => brec.msds_filename = filename} />
                                            </td>
                                        </tr>
                                        : <></>
                                }
                                {
                                    (brec.commodityType && brec.commodityType.startsWith('Non-Hazardous Chemicals')) ?
                                        <tr>
                                            <td>Non-DG Declaration</td>
                                            <td>{renderLink(brec.nondgdecl_filename)}</td>
                                            <td>
                                                <AgFile cat={filecat} filetype='nondgdecl'
                                                    callback={(filename) => brec.nondgdecl_filename = filename} />
                                            </td>
                                        </tr> : <></>
                                }
                                {
                                    (brec.contract.hazardous === 'Yes') ?
                                        <>
                                            <tr>
                                                <td>DG Declaration</td>
                                                <td>{renderLink(brec.dgdecl_filename)}</td>
                                                <td>
                                                    <AgFile cat={filecat} filetype='dgdecl'
                                                        callback={(filename) => brec.dgdecl_filename = filename} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>IIP Certificate</td>
                                                <td className={validIIPCertificate ? '' : 'is-danger-border'}>{renderLink(brec.iipcert_filename)}</td>
                                                <td>
                                                    <AgFile cat={filecat} filetype='iipcert'
                                                        callback={(filename) => brec.iipcert_filename = filename} />
                                                </td>
                                            </tr>
                                        </> : <></>
                                }
                            </tbody>
                        </table>
                    </div>
                </$c>
            </div>
            <div className="columns">
                <$cr n='1'></$cr>
                <$c n='9'>
                    <button className="button is-warning" onClick={(e) => {
                        configStore.scheduleOrCarrierform = 'schedule'
                        hideme(false)
                    }}>
                        <span className="icon" style={{ marginRight: '5px' }}>
                            <i class="fa-solid fa-circle-left"></i>
                        </span>
                        Real-time Pricing
                    </button>
                    <button className="button is-link" style={{ float: 'right' }} onClick={onSubmit}  disabled={isButtonClicked}>
                        Confirm and Save Booking
                    </button>
                </$c>
            </div>
        </div>
    )
}