import { ROLES, PERMISSIONS, VERTICALS, DIVISION } from "../util/constants"
import { loginStore } from "../state/CommonState"

class RoleUtils {
  static rolePermissions = {
    [PERMISSIONS.NAV_MENU.ALL_ACCESS]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
    ],
    [PERMISSIONS.NAV_MENU.USERS]: [
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.HR, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
      {
        role: ROLES.DT.CUSTOMER_SUCCESS,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
    ],
    [PERMISSIONS.NAV_MENU.SPOT_BOOKING_LCL]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.PROCUREMENT, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.CUSTOMER_SUCCESS,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],
    [PERMISSIONS.NAV_MENU.UPLOAD_SCHEDULES]: [
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.BPO, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.PROCUREMENT, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],
    [PERMISSIONS.NAV_MENU.ENQUERIES_RECEIVED]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.PROCUREMENT, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],
    [PERMISSIONS.NAV_MENU.QUOTATIONS_CB]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.PROCUREMENT, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],
    [PERMISSIONS.NAV_MENU.BOOKINGS_CB]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],
    [PERMISSIONS.NAV_MENU.VISIBILITY]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.PROCUREMENT, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],
    [PERMISSIONS.NAV_MENU.MY_ACTIONS_CB]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      // { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],
    [PERMISSIONS.NAV_MENU.QUOTATIONS_DT]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.CUSTOMER_SUCCESS,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],
    [PERMISSIONS.NAV_MENU.BOOKINGS_DT]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.CUSTOMER_SUCCESS,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.DT.DT_OPERATIONS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.DT.INVOICE_LEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],
    [PERMISSIONS.NAV_MENU.MY_ACTIONS_DT]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      // { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.DT.DT_OPERATIONS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.CUSTOMER_SUCCESS,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.DT.INVOICE_LEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],
    [PERMISSIONS.NAV_MENU.UPLOADS]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      
    ],
    [PERMISSIONS.NAV_MENU.CONFIG]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
    ],
    [PERMISSIONS.NAV_MENU.ENTITIES_V2]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.CUSTOMER_SUCCESS,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.DT.DT_OPERATIONS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],
    [PERMISSIONS.NAV_MENU.ENTITIES]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.CUSTOMER_SUCCESS,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.DT.DT_OPERATIONS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],
    [PERMISSIONS.NAV_MENU.VEHICLES]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.CUSTOMER_SUCCESS,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.DT.DT_OPERATIONS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],
    [PERMISSIONS.NAV_MENU.DRIVERS]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.CUSTOMER_SUCCESS,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.DT.DT_OPERATIONS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],
    [PERMISSIONS.NAV_MENU.AUDIT]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.HR, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.PROCUREMENT, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.CUSTOMER_SUCCESS,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.DT.DT_OPERATIONS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.DT.INVOICE_LEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
    ],
    [PERMISSIONS.NAV_MENU.ACCESS_CONTROL]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.HR, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.PROCUREMENT, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.CUSTOMER_SUCCESS,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.DT.DT_OPERATIONS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.DT.INVOICE_LEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    
    ],
    [PERMISSIONS.NAV_MENU.REPORTS]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.HR, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.PROCUREMENT, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.CUSTOMER_SUCCESS,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.DT.DT_OPERATIONS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.DT.INVOICE_LEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
  
    ],
    [PERMISSIONS.NAV_MENU.APPROVALS]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.DT.CUSTOMER_SUCCESS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    ],
    [PERMISSIONS.NAV_MENU.CROSS_BORDER]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.BPO, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.PROCUREMENT, vertical: VERTICALS.CROSS_BORDER },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],
    [PERMISSIONS.NAV_MENU.DOMESTIC_TRANSPORT]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.CUSTOMER_SUCCESS,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.DT.DT_OPERATIONS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.DT.INVOICE_LEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
   
    ],
    [PERMISSIONS.NAV_MENU.AUDIT]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
    ],
    [PERMISSIONS.NAV_MENU.SITE_INFORMATION]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
    ],
    [PERMISSIONS.NAV_MENU.HOME]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
 
  
    ],
    [PERMISSIONS.NAV_MENU.ANALYTICS]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CB.PROCUREMENT, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
   
    ],
    [PERMISSIONS.NAV_MENU.REMAINING_ANALYTICS]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    ],
    [PERMISSIONS.NAV_MENU.ANALYTICS_FCL_PRICING]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CB.PROCUREMENT, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
   
   
    ],
    [PERMISSIONS.NAV_MENU.OPERATIONS_CB]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER },
  
    ],
    [PERMISSIONS.NAV_MENU.SALES_CB]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER },
    ],
    [PERMISSIONS.OLD.NEW_QUOTATIONS]: [
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.PROCUREMENT, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
   
    ],
    [PERMISSIONS.OLD.NEW_ENQUIRY_QUOTATIONS]: [
      // { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      // { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      // { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      // { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      // { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.PROCUREMENT, vertical: VERTICALS.CROSS_BORDER },
      // { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
    ],
    [PERMISSIONS.OLD.MYACTIONS_TYPE]: [
      // { role: "SALES", vertical: VERTICALS.CENTRAL },
      // { role: "OPERATIONS", vertical: VERTICALS.CENTRAL },
      // { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      // { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      // { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
    ],
    [PERMISSIONS.OLD.MYACTIONS_ROLE]: [
      { role: "SALES", vertical: VERTICALS.CENTRAL },
      // { role: "OPERATIONS", vertical: VERTICALS.CENTRAL },
      // { role: ROLES.DT.DT_OPERATIONS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      // { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
    ],
    

   [PERMISSIONS.EDIT.PROCUREMENT_ONLY_SUBMIT ]: [
    { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
    { role: ROLES.CB.BRANCH_HEAD,vertical:VERTICALS.CROSS_BORDER},
    { role: ROLES.CB.RELATIONSHIP_MANAGER,vertical:VERTICALS.CROSS_BORDER},
    { role: ROLES.CB.CUSTOMER_SUCCESS,vertical:VERTICALS.CROSS_BORDER},
    { role: ROLES.CB.PROCUREMENT, vertical: VERTICALS.CROSS_BORDER },
    { role: ROLES.CB_DT.ADMIN,vertical:VERTICALS.CB_DT},
    { role:ROLES.CB.ADMIN,vertical: VERTICALS.CROSS_BORDER},
    { role: ROLES.CB_DT.BRANCH_HEAD,vertical:VERTICALS.CB_DT},
    { role: ROLES.CB_DT.RELATIONSHIP_MANAGER,vertical:VERTICALS.CB_DT},
    { role: ROLES.CB_DT.CUSTOMER_SUCCESS,vertical:VERTICALS.CB_DT},

    // { role: ROLES.CB.CUSTOMER_SUCCESS,vertical:VERTICALS.CROSS_BORDER},
  ],
  [PERMISSIONS.EDIT.AGRAGA_ENQUIRY_BUTTON ]: [
    { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
    { role: ROLES.CB.BRANCH_HEAD,vertical:VERTICALS.CROSS_BORDER},
    { role: ROLES.CB.RELATIONSHIP_MANAGER,vertical:VERTICALS.CROSS_BORDER},
    { role: ROLES.CB.CUSTOMER_SUCCESS,vertical:VERTICALS.CROSS_BORDER},
    { role:ROLES.CB.ADMIN,vertical: VERTICALS.CROSS_BORDER},
    { role: ROLES.CB_DT.ADMIN,vertical:VERTICALS.CB_DT},
    { role: ROLES.CB_DT.BRANCH_HEAD,vertical:VERTICALS.CB_DT},
    { role: ROLES.CB_DT.RELATIONSHIP_MANAGER,vertical:VERTICALS.CB_DT},
    { role: ROLES.CB_DT.CUSTOMER_SUCCESS,vertical:VERTICALS.CB_DT},
  ],
    // [PERMISSIONS.EDIT.VEHICLES_APPROVE_REJECT]: [
    //   { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
    //   { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT },
    // ],
    // [PERMISSIONS.EDIT.DRIVERS_APPROVE_REJECT]: [
    //   { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
    //   { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT },
    // ],
    [PERMISSIONS.EDIT.SAVE_UPLOAD_SCHEDULES_ONLY]: [
      { role: ROLES.CENTRAL.BPO, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
    ],
    [PERMISSIONS.EDIT.CUSTOMER_REF_NUMBER]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.CUSTOMER_SUCCESS,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.DT.DT_OPERATIONS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
      
    ],
    [PERMISSIONS.EDIT.ERP_NUMBER]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.DT.DT_OPERATIONS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    

    ],
    [PERMISSIONS.EDIT.DESTINATION_DETAILS]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      // { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      // { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.DT.DT_OPERATIONS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
    { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    

    ],
    [PERMISSIONS.EDIT.ENTITIES]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.CUSTOMER_SUCCESS,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    
    ],
    [PERMISSIONS.EDIT.CONVERT_QUOTATIONS_TO_CONTRACTS]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      // { role: ROLES.CENTRAL.BPO, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.CUSTOMER_SUCCESS,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    

    ],[PERMISSIONS.EDIT.CB_QUOTATION_AR]:[    
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },  
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },  
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },  
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },],
      [PERMISSIONS.EDIT.CB_QUOTATION_CREATE_DUMMY]:[    
        { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },  
        { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },  
        { role: ROLES.CENTRAL.BPO, vertical: VERTICALS.CENTRAL },  
        { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },  
        { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },  
        { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
        { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
        { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
        { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
        { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
        { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
        { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
        { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
    { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    

      ],
    [PERMISSIONS.EDIT.VEHICLE_STATUS]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.DT.DT_OPERATIONS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
    { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    

    ],
    [PERMISSIONS.EDIT.VENDOR_DETAILS]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      // { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      // { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.DT.DT_OPERATIONS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
    { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
  

    ],
    [PERMISSIONS.EDIT.DRIVER_DETAILS]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.DT.DT_OPERATIONS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
    { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    

    ],
    [PERMISSIONS.EDIT.VEHICLE_DETAILS]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.DT.DT_OPERATIONS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
    { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    

    ],
    [PERMISSIONS.EDIT.STOP_TRACKING]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.DT.DT_OPERATIONS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
    { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    

    ],
    [PERMISSIONS.EDIT.ENTITIESV2_VENDOR_RTREJECTED]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
    ],
    [PERMISSIONS.EDIT.REJECTED_VEHICLES]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
    { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    

    ],
    [PERMISSIONS.EDIT.REJECTED_DRIVERS]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
    { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    

    ],
    [PERMISSIONS.EDIT.AGRAGA_FREIGHT_INVOICE_DETAILS]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      // { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      // { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.DT.INVOICE_LEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
    { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    

    ],
    [PERMISSIONS.EDIT.PENDING_VENENTITYV2RT_VR]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
// 
    ],
    [PERMISSIONS.CREATE.NEW_VEHICLES]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    

    ],
    [PERMISSIONS.CREATE.NEW_DRIVERS]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
    { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],
    [PERMISSIONS.CREATE.NEW_ENTITYV2_ROADTRANSPORT]: [
      // { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      // { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      // { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
     
    ],
    [PERMISSIONS.CREATE.NEW_ENTITYV2_ROADTRANSPORT_ONLY]: [
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT },
     // { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
     // { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      
    // { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
     
     
     // {
      //   role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY,
      //   vertical: VERTICALS.DOMESTIC_TRANSPORT,
      // },
      // {
      //   role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
      //   vertical: VERTICALS.DOMESTIC_TRANSPORT,
      // },
    ],
    [PERMISSIONS.CREATE.NEW_ENTITYV2_CUSTOMERS]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      // { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      // { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      // {
      {   role: ROLES.DT.CUSTOMER_SUCCESS,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.CB_DT.CUSTOMER_SUCCESS,
        vertical: VERTICALS.CB_DT,
      },
      {
        role: ROLES.CB_DT.RELATIONSHIP_MANAGER,
        vertical: VERTICALS.CB_DT,
      },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      // cehck vendor freeze
      
    ],
    [PERMISSIONS.CREATE.NEW_ENTITYV2_CUSTOMERS_CB_DT]: [
      // {   role: ROLES.DT.CUSTOMER_SUCCESS,
      //   vertical: VERTICALS.DOMESTIC_TRANSPORT,
      // },
      // {
      //   role: ROLES.CB_DT.CUSTOMER_SUCCESS,
      //   vertical: VERTICALS.CB_DT,
      // },
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      
     // { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      // cehck vendor freeze
      
    ],
    [PERMISSIONS.CREATE.INITIATE_ADVANCE]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.DT.DT_OPERATIONS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    

    ],
    [PERMISSIONS.CREATE.INITIATE_BALANCE_PAYMENT]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.DT.DT_OPERATIONS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
    { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    

    ],
    [PERMISSIONS.CREATE.NEW_ENTITYV2]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.CUSTOMER_SUCCESS,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
    { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
    { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
    { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    
    ],
    [PERMISSIONS.CREATE.NEW_QUOTATIONS]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.CUSTOMER_SUCCESS,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
  
    ],
    [PERMISSIONS.CREATE.BOOKING]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    

    ],
    [PERMISSIONS.CREATE.DTBOOKING]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.CUSTOMER_SUCCESS,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
    { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    

    ],
    [PERMISSIONS.EDIT.CUSTOMER_ENTITYV2]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      // { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.CUSTOMER_SUCCESS,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
    { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    

    ],
    [PERMISSIONS.EDIT.VENDOR_RT_ENTITYV2]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
    { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
   // { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
   // { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    

    ],
    [PERMISSIONS.EDIT.CUSTOMER_USERS]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.CUSTOMER_SUCCESS,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
    { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    

    ],
    [PERMISSIONS.CREATE.NEW_USER]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.HR, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },

      // { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      // { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      // { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      // { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      // {
      //   role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND,
      //   vertical: VERTICALS.DOMESTIC_TRANSPORT,
      // },
      // {
      //   role: ROLES.DT.CUSTOMER_SUCCESS,
      //   vertical: VERTICALS.DOMESTIC_TRANSPORT,
      // },
      // { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      // { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      // { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      // { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      // { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      // { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      // { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],
    [PERMISSIONS.EDIT.ENQUIRYTOQUOTATION]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      // { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      // { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.PROCUREMENT, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
    { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    // { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    // { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],
    [PERMISSIONS.EDIT.REJECTED_ENTITES_V2_CUSTOMER]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
    ],
    [PERMISSIONS.CREATE.NEW_ENTITY]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },

      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      {
        role: ROLES.DT.CUSTOMER_SUCCESS,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
   { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
   { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
   { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
   { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
   { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
   { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
   
    ],
    [PERMISSIONS.CREATE.NEW_CUSTOMER_USER]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.CUSTOMER_SUCCESS,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
     { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
     { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],
    [PERMISSIONS.EDIT.VENDOR_EXCEPTRT_ENTITYV2]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL }
    ],
    [PERMISSIONS.CREATE.NEW_ENTITYV2_REMVENDOR]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
    ],
    [PERMISSIONS.CREATE.NEW_AGRAGAUSER]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.HR, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
    ],
    [PERMISSIONS.EDIT.ENTITIES_STATUS]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
    ],
    [PERMISSIONS.EDIT.PENDING_CUSENTITYV2_VR]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
    
    ],
    [PERMISSIONS.EDIT.PENDING_VENENTITYV2_VR]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      
    ],
    [PERMISSIONS.EDIT.VEHICLES_APPROVE_REJECT]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
     // { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      
    ],
    [PERMISSIONS.EDIT.DRIVERS_APPROVE_REJECT]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
     // { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      
    ],

  
   
 
  [PERMISSIONS.EDIT.APPROVAL_VEHICLES]: [
    { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
     { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
     { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
    { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
  ],
  [PERMISSIONS.EDIT.APPROVAL_DRIVERS]: [
     { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
     { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
    { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
    { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
  ],

  [PERMISSIONS.EDIT.APPROVAL_VEHICLES_ACKNOWLEDGEMENT]: [
    { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
     { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
     { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
     { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
     { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
    { role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY, vertical: VERTICALS.DOMESTIC_TRANSPORT },
    {
      role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
      vertical: VERTICALS.DOMESTIC_TRANSPORT,
    },

     { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
  ],
  [PERMISSIONS.EDIT.APPROVAL_DRIVERS_ACKNOWLEDGEMENT]: [
     { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
     { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
     { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
    { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
    { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
    { role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY, vertical: VERTICALS.DOMESTIC_TRANSPORT },
    {
      role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
      vertical: VERTICALS.DOMESTIC_TRANSPORT,
    },

    { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    
  ],
  [PERMISSIONS.EDIT.APPROVAL_VEHICLES_ACKNOWLEDGEMENT]: [
    { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
     { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
     { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
     { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
     { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
    { role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY, vertical: VERTICALS.DOMESTIC_TRANSPORT },
    {
      role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
      vertical: VERTICALS.DOMESTIC_TRANSPORT,
    },

     { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
  ],
  
  [PERMISSIONS.EDIT.APPROVAL_CUSTOMER_ENTITY_ACKNOW]: [
    { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
     { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
    // { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
     { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
     { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
    { role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY, vertical: VERTICALS.DOMESTIC_TRANSPORT },
    { role: ROLES.DT.CUSTOMER_SUCCESS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
    { role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND, vertical: VERTICALS.DOMESTIC_TRANSPORT },
    {
      role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
      vertical: VERTICALS.DOMESTIC_TRANSPORT,
    },


    { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },

     { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    

  ],
  
  [PERMISSIONS.EDIT.APPROVAL_VENDOR_RT_ENTITY_ACKNOW]: [
   
     { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
     { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
    { role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY, vertical: VERTICALS.DOMESTIC_TRANSPORT },
    
    {
      role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
      vertical: VERTICALS.DOMESTIC_TRANSPORT,
    },
     { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    // need to check this
    //{ role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
     

  ],
  [PERMISSIONS.EDIT.APPROVAL_VENDOR_ENTITY_ACKNOW]: [
   
   { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
    { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
    { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
     

  ],

  [PERMISSIONS.EDIT.APPROVAL_VENDOR]: [
    { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
     { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
     { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
     
   
  ],
  [PERMISSIONS.EDIT.APPROVAL_RT_VENDOR]: [
    { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
    { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
  
   
    { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
  ],
  [PERMISSIONS.EDIT.APPROVAL_RT_VENDOR_BRANCH]: [
    { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
    { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
    
  
  ],
    [PERMISSIONS.EDIT.UPLOADS_FILE]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
    ],
    [PERMISSIONS.EDIT.MYACTIONS_BUSINESS]: [
      // { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: "OPERATIONS", vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.DT_OPERATIONS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    ],
    [PERMISSIONS.EDIT.MYACTIONS_BUSINESS_VIEW]:[
      { role: ROLES.DT.INVOICE_LEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.DT.CUSTOMER_SUCCESS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
     // { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],
    [PERMISSIONS.EDIT.MYACTIONS_BUSINESS_TAB_VIEW]:[
      { role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.DT.CUSTOMER_SUCCESS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.DT.INVOICE_LEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],
    [PERMISSIONS.EDIT.MYACTIONS_OPERATIONS]: [
      // { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: "OPERATIONS", vertical: VERTICALS.CENTRAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],
    [PERMISSIONS.EDIT.MYACTIONS_FINANCE]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: "OPERATIONS", vertical: VERTICALS.CENTRAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL},
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],
    [PERMISSIONS.EDIT.MYACTIONS_FINANCE_BUSINESS_VIEW]: [
      // { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
    ],
    [PERMISSIONS.EDIT.MYACTIONS_FINANCE_OPERATIONS_VIEW] : [
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.DT.CUSTOMER_SUCCESS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.DT.DT_OPERATIONS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.DT.INVOICE_LEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],
    [PERMISSIONS.EDIT.APPROVALS_FINANCE]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
    ],
    [PERMISSIONS.EDIT.APPROVALS_SALES]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.DT.CUSTOMER_SUCCESS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    ],
    [PERMISSIONS.EDIT.APPROVALS_OPERATIONS]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
    ],
    [PERMISSIONS.EDIT.MY_AGRAGA_ACTIONS_OPERATIONS]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: "OPERATIONS", vertical: VERTICALS.CENTRAL },
    ],
    [PERMISSIONS.EDIT.CREDIT_APPROVALS]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: "OPERATIONS", vertical: VERTICALS.CENTRAL },
    ],
    [PERMISSIONS.EDIT.MYACTIONS_CUSTOMER_FOLLOW_UP]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: "OPERATIONS", vertical: VERTICALS.CENTRAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],
    [PERMISSIONS.EDIT.EDIT_VISIBILITY]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
    ],
    [PERMISSIONS.EDIT.EDIT_UPLOADS]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
    ],
    [PERMISSIONS.EDIT.ENTITIES_VIEW]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.DT.DT_OPERATIONS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.CUSTOMER_SUCCESS,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.DT.INVOICE_LEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],
    [PERMISSIONS.EDIT.ENTITIESV2_VIEW]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.CUSTOMER_SUCCESS,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.DT.DT_OPERATIONS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.DT.INVOICE_LEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],
    [PERMISSIONS.EDIT.ENTITIESV2_RT_VIEW]: [
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.CUSTOMER_SUCCESS,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.DT.DT_OPERATIONS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.DT.INVOICE_LEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],
    [PERMISSIONS.EDIT.ENTITIESV2_VENDOR_VIEW]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
    ],
    [PERMISSIONS.EDIT.ENTITIES_VERIFIED]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
    ],
    [PERMISSIONS.EDIT.ENTITIES_KYCVERIFIED]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
    ], 
    [PERMISSIONS.EDIT.AGRAGA_USER]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.HR, vertical: VERTICALS.CENTRAL },
    ],
    [PERMISSIONS.EDIT.BOOKING_ACTION]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
    ],
    [PERMISSIONS.EDIT.CB_CUSTOMER_REF_NUMBER]: [
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],
    [PERMISSIONS.EDIT.CB_ERP_SHIPMENT_NUMBER]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
    ],
    [PERMISSIONS.EDIT.Razor_Pay]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
    ],
    
    
    [PERMISSIONS.EDIT.GENERATE_ENTITIES]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },

      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
       {
        role: ROLES.DT.CUSTOMER_SUCCESS,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.DT.DT_OPERATIONS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
       
     { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
       { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },

       { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
       { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
       { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
       { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
     { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
     { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      
    ],
    [PERMISSIONS.EDIT.GENERATE_ENTITIES_VENDOR_RT]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },

      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.DT.DT_OPERATIONS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
       
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
  
    ],
    [PERMISSIONS.EDIT.GENERATE_DRIVERS]: [
        { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },

      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.DT.DT_OPERATIONS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],
    [PERMISSIONS.EDIT.GENERATE_VEHICLES]: [
       { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },

      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.DT.DT_OPERATIONS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
     
    ],
    [PERMISSIONS.EDIT.GENERATE_USERS]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },

      { role: ROLES.CENTRAL.HR, vertical: VERTICALS.CENTRAL },
            
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.CUSTOMER_SUCCESS,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },

      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
       { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },

       { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
       { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
       { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
       { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
     { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
     { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      
    ],
    [PERMISSIONS.EDIT.GENERATE_USERS_AGRAGA_ONLY]: [
      { role: ROLES.CENTRAL.HR, vertical: VERTICALS.CENTRAL },
      
    ],
    [PERMISSIONS.EDIT.GENERATE_DT_REPORTS]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },

      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.CUSTOMER_SUCCESS,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      { role: ROLES.DT.DT_OPERATIONS, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      { role: ROLES.DT.INVOICE_LEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },

      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
     
    ],
    [PERMISSIONS.EDIT.GENERATE_REAL_TIME_PRICING]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },

      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.NATIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.REGIONAL },
      { role: ROLES.DT.ADMIN, vertical: VERTICALS.DOMESTIC_TRANSPORT, division: DIVISION.ZONAL },
      { role: ROLES.DT.BRANCH_HEAD, vertical: VERTICALS.DOMESTIC_TRANSPORT },
      {
        role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },
      {
        role: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
        vertical: VERTICALS.DOMESTIC_TRANSPORT,
      },   
     
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      {role:ROLES.CB.PROCUREMENT, vertical:VERTICALS.CROSS_BORDER},
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },

      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],
    [PERMISSIONS.EDIT.GENERATE_DSR_MY_ACTION_REPORT]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },

      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
       { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },

       { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
       { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
       { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
       { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
     { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
     { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      
    ],
    [PERMISSIONS.EDIT.GENERATE_DSR_NOT_UPDATED]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },


      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
       
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],
    [PERMISSIONS.EDIT.GENERATE_MY_ACTIONS]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },

      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],

    [PERMISSIONS.EDIT.GENERATE_ENQUIRY]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },

      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      {role:ROLES.CB.PROCUREMENT,vertical:VERTICALS.CROSS_BORDER},
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],
    [PERMISSIONS.EDIT.GENERATE_QUOTATION_REPORT]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
     
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      {role:ROLES.CB.PROCUREMENT, vertical:VERTICALS.CROSS_BORDER},
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],
    [PERMISSIONS.EDIT.GENERATE_SALES_REPORT]: [
      { role: ROLES.CENTRAL.AGRAGA_ADMIN, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.TECHANDPRODUCT, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.DATABASE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.FINANCE, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.CENTRAL_OPERATIONS, vertical: VERTICALS.CENTRAL },
      { role: ROLES.CENTRAL.PRODUCT_ADOPTION, vertical: VERTICALS.CENTRAL },
     
      { role: ROLES.CB.RELATIONSHIP_MANAGER, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.CUSTOMER_SUCCESS, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.BRANCH_HEAD, vertical: VERTICALS.CROSS_BORDER },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.NATIONAL },
      { role: ROLES.CB.ADMIN, vertical: VERTICALS.CROSS_BORDER, division: DIVISION.ZONAL },
      
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.REGIONAL },
      { role: ROLES.CB_DT.ADMIN, vertical: VERTICALS.CB_DT, division: DIVISION.ZONAL },
      { role: ROLES.CB_DT.BRANCH_HEAD, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.RELATIONSHIP_MANAGER, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    { role: ROLES.CB_DT.CUSTOMER_SUCCESS, vertical: VERTICALS.CB_DT, division: DIVISION.NATIONAL },
    
    ],
  };

  static isUserAuthorized(permissionToCheck) {
    let userRole = loginStore.userRec.aguserRec.role
    const userVertical = loginStore.userRec.aguserRec.vertical
    const userDivision = loginStore.userRec.aguserRec.division
    if ((userRole === ROLES.CB.ADMIN && userDivision === DIVISION.REGIONAL && userVertical === VERTICALS.CROSS_BORDER) || (userRole === ROLES.CB.ADMIN && userDivision === DIVISION.ZONAL && userVertical === VERTICALS.CROSS_BORDER)) {
      userRole = ROLES.CB.BRANCH_HEAD
    } else if ((userRole === ROLES.DT.ADMIN && userDivision === DIVISION.REGIONAL && userVertical === VERTICALS.DOMESTIC_TRANSPORT) || (userRole === ROLES.DT.ADMIN && userDivision === DIVISION.ZONAL && userVertical === VERTICALS.DOMESTIC_TRANSPORT)) {
      userRole = ROLES.DT.BRANCH_HEAD
    }

    if (this.rolePermissions[permissionToCheck]) {
      for (const permittedRoles of this.rolePermissions[permissionToCheck]) {
        const roleVertical = permittedRoles.vertical
        const role = permittedRoles.role
        if (permissionToCheck === PERMISSIONS.NAV_MENU.CROSS_BORDER) {
          if (userRole === role) {
            return true
          }
        }
        if (permissionToCheck === PERMISSIONS.EDIT.Razor_Pay) {
          if (userRole === role) {
            return true
          }
        }

        if (userRole === role && userVertical === roleVertical) {
          return true
        }
      }
    }
    return false
  }

  static getUserRole = () => {
    const userRec = loginStore.userRec.aguserRec
    return userRec.role
  };
}

export default RoleUtils
