import {
  configStore,
  quoteStore,
  setCommonState,
  quoteDataRec,
  containerStore,
  cargoStore,
  chargesStore1,
  fclChargesStore,
  toEmailStore,
  ccEmailStore,
  loginStore,
  customerStore,
  airChargesStore,
  contractsStore,
  dataListStore,
  quotatationImportStore,
  aguserStore,
  routerStore,
  vehicleStore,
  entityStore,
  EntityV2Store
} from "./CommonState";
import QuoteView from "../QuoteView";
import EntityApi from "../state/entity-api";
import ReactDOMServer from "react-dom/server";
import { ServerLists } from "../StaticData";
import Utils from "../util/Utils";
import QuoteCalc from "../util/quote-calc";
import { PricingContractSaved, PricingQuoteSaved, QuoteSavedMessage, QuoteSavedMessage1 } from "../components/AgComponents";
import { enquiryStore } from "../enquiry/enquiry-data";
import { calcCargoDetails } from "../CargoComp";
import AguserApi from "../state/AguserApi";
import { menucountStore } from "./menuStore";
import { VehicleType } from "./Types";
import MonApi from "./mongo-api";
import QuoteViewv2 from "../quotes/quote-view-v2";
import QuoteCalcV2 from "../util/quote-calc-v2";
import trackMyBookingOceanStore from "../trackmybookings/TrackMyBooking-store";
import airlineCodes from "../static-data/airlines";
import { useEffect } from "react";
import EnquiriesApi from "./enq-api";

const GET_QUOTES_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/quotes`;
const GET_QUOTE_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/quotes`;

const POST_QUOTE_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/quotes`;
const GET_CONTRACTS_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/contracts`;
const CREATE_CONTRACT_FROM_QUOTE_URI = (id) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/contracts/${id}`;
const GET_CONTRACT_URI = (id) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/contracts/${id}`;
const DECLINE_CONTRACT = (id) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/contracts/decline/${id}`;
const SCHEDULES_CONTRACT = (id) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/contracts/schedules/${id}`;
const WON_QUOTES = (id) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/quotes/won/${id}`;
const LOST_QUOTES = (id) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/quotes/lost/${id}`;
const INVALID_QUOTES = (id) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/quotes/invalid/${id}`;
const AGRAGA_ENQUIRY_STATUS = (id) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/quotes/agragaenquirystatus/${id}`;

const LASTUPLOAD = (type) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/common/getlastupload/${type}`;
const POST_EMAIL_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/quotes/send/email`;
const POST_EMAIL2_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/quotes/sendv2/email`;
const POST_EMAIL3_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/quotes/sendv3/email`;
const POST_EMAILWITHPDF_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/quotes/send/emailwithpdf`;
const VALIDATE_EMAIL_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/common/getcustidforemail`;
const GET_USER4EMAILPASSWORD = `${process.env.REACT_APP_API_SERVER}/api/v1/common/getuser4emailpassword`;
const UPDATE_QUOTE_STATUS = `${process.env.REACT_APP_API_SERVER}/api/v1/quotes/update/status`;
const GET_CUSTOMERS_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/customer`;
const GET_UNLOC_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/common/getunloc`;
const GET_4UNLOC_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/common/get4unloc`;
const GET_MAP_UNLOC_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/common/getmapunloc`;
const GET_EXCHANGERATES_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/common/getexchangerates`;
const CREATE_CUSTOMER_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/customer`;
const TRACK_BOOKINGS_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/trackmybookings`;
const GET_COUNTRYLIST_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/common/getcountrylist`;
const SEND_CLIENTMAIL_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/common/sendclientmail`;
const SEND_CLIENTMAIL_URI1 = `${process.env.REACT_APP_API_SERVER}/api/v1/common/sendclientmail1`;
const UPDATE_CUSTOMER_REFERENCE_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/trackmybookings/updateCustomerReference`
const UPDATE_DOCUMENT_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/trackmybookings/update/documents`
const UPDATE_PHONE_NUMBER_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/trackmybookings/updatePhoneNumber`
const DELETE_BOOKING_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/trackmybookings/delete/`
const GET_BEST_MATCH4ENQUIRY = `${process.env.REACT_APP_API_SERVER}/api/v1/contracts/get/contract/for/enquiry`;
const GET_QUOTATION_DOCUMENT_URI = (quoteNum) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/quotes/getQuotationDocument/${quoteNum}`;
const GET_QUOTATION_DOCUMENT_URI1 = (quoteNum) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/quotes/getQuotationDocument1/${quoteNum}`;
const GET_QUOTATION_DOCUMENT_URI2 = (quoteNum) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/quotes/getQuotationDocument2/${quoteNum}`;
const GET_CAF_DOC_URI = (CafId) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/getCreditDocument/${CafId}`;
const GET_CAF_FORM_URI = (CafId) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/getCreditFormDoc/${CafId}`;
const QUOTATIONS_SHEET_IMPORT_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/quotes/import`;
const GET_ENQUIRYSTATUS_URI =(id) => `${process.env.REACT_APP_API_SERVER}/api/v1/quotes/setenquirystatus/${id}`;
const DELETE_QUOTE_URI = (quoteNum) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/quotes/delete/${quoteNum}`;
const GET_FCL_CARRIER_LIST_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/dataList/getFCLCarrierList`;
const GET_P44_FCL_CARRIER_LIST_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/dataList/getP44FCLCarrierList`;
const CHECK_DRIVER_STATUS_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/trackmybookings/checkDriverStatus/`
const END_TRIP_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/trackmybookings/endTrip/`
const UPDATE_OUTCOME_STATUS_URI = (id) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/quotes/updateOutCome/${id}`;

const GETLCLSCHEDULESMASTER = `${process.env.REACT_APP_API_SERVER}/api/v1/myactions/getschedules/lcl`;

const CHECKUNLOC = `${process.env.REACT_APP_API_SERVER}/api/v1/common/checkunloc`;
const MENUCOUNT =
  `${process.env.REACT_APP_API_SERVER}/api/v1/common/getcount/` +
  Utils.getCurrentDateString();
const EDITDSR = `${process.env.REACT_APP_API_SERVER}/api/v1/myactions/edituploaddsr`;
const GETPROJECT44ID = (id) => `${process.env.REACT_APP_API_SERVER}/api/v1/trackmybookings/getByBookingId/${id}`;
const GETINTERMOID = (id) => `${process.env.REACT_APP_API_SERVER}/api/v1/trackmybookings/getintermo/${id}`;
const GETPROJECT44 = (id) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/myactions/getproject44/${id}`;
const GETINTERMO = (id) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/myactions/getintermo/${id}`;

const getRespectiveMember = (customer) =>{
    if(customer?.crossBorder?.relationshipManager && customer?.crossBorder?.customerService){
        return `${customer?.crossBorder?.relationshipManager} ,${customer?.crossBorder?.customerService}`
    }else if(customer?.domesticTransport?.relationshipManager && customer?.domesticTransport?.customerService){
        console.log(customer?.domesticTransport?.relationshipManager,"***",customer?.domesticTransport?.customerService);
        return `${customer?.domesticTransport?.relationshipManager} ,${customer?.domesticTransport?.customerService}`
    }else{
        return `pat@agraga.com`
    }
}

export default class Api {
  static H(headers = {}) {
    let h = { ...headers };
    let Entity = "ALL";
    let Branch = "ALL";
    console.log("kkk", loginStore.isClient());
    if (loginStore.isClient()) {
      Entity = configStore.currentSelectedEntity?.entityId; 
      Branch = configStore.currentSelectedBranch?.branchId; 
      h["BranchList"] = JSON.stringify(configStore.currentSelectedEntity.branches);
    }
    h["Aguser"] = loginStore.email;
    h["Role"] =
      loginStore.userRec && loginStore.userRec.aguserRec
        ? loginStore.userRec.aguserRec.role
        : "none";
    h["Current-Entity"] = Entity;
    h["Current-Branch"] = Branch;

    h["Content-Type"] = "application/json";
    h["Timestamp"] = Date.now();
    h["agtoken"] = "310c3d1d-85a3-4a8d-93ad-1709c7c7cca2";
    h["Devicetoken"] = localStorage.getItem("devicetoken");
    return h;
  }
  static H1(headers = {}) {
    let h = { ...headers };
    let Entity = "ALL";
    let Branch = "ALL";
    console.log("kkk", loginStore.isClient());
    if (loginStore.isClient()) {
      Entity = configStore.currentSelectedEntity.entityId; 
      Branch = configStore.currentSelectedBranch.branchId; 
      h["BranchList"] = JSON.stringify(configStore.currentSelectedEntity.branches);
    }
    h["Aguser"] = loginStore.email;
    h["Role"] = loginStore.userRec.aguserRec
      ? loginStore.userRec.aguserRec.role
      : "none"; 
      h["Current-Entity"] = Entity;
      h["Current-Branch"] = Branch;
      h["BranchList"] = Entity;
    h["Timestamp"] = Date.now();
    h["Devicetoken"] = localStorage.getItem("devicetoken");
    return h;
  }
  static fetch(url, datacallback = (f) => f, errcallback = (f) => f) {
    let entity = "ALL";
    if (loginStore.isClient()) entity = configStore.currentSelectedEntity.entityId;
    const requestOptions = {
      headers: Api.H(),
    };
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        //console.log('Url: ' + url + ' Ret=', JSON.stringify(data))
        if (data.error) {
          if (data.error != "No Error" || data.error != "Invalid Token") {
            if (data.ajvErrors) {
              configStore.setModalMessage(data.error, data.ajvErrors);
            } else {
              configStore.setModalMessage(data.error);
            }
          }
        } else {
          datacallback(data);
        }
      })
      .catch((err) => {
        if (err != "No Error" || err != "Invalid Token") {
          console.log("Communication Error. Url: " + url + " Err=", err);
          configStore.setModalMessage(
            "Server is not running. Please check browser console for specific error."
          );
        }
      });
  }
  static async fetch1(url, datacallback = (f) => f, errcallback = (f) => f) {
    let entity = "ALL";
    if (loginStore.isClient()) entity = configStore.currentSelectedEntity.entityId;
    const requestOptions = {
      headers: Api.H(),
    };
    await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        //console.log('Url: ' + url + ' Ret=', JSON.stringify(data))
        if (data.error) {
          if (data.error != "No Error" || data.error != "Invalid Token") {
            if (data.ajvErrors) {
              configStore.setModalMessage(data.error, data.ajvErrors);
            } else {
              configStore.setModalMessage(data.error);
            }
          } else {
            localStorage.removeItem("checkcount");
          }
        } else {
          datacallback(data);
        }
      })
      .catch((err) => {
        if (err != "No Error" || err != "Invalid Token") {
          console.log("Communication Error. Url: " + url + " Err=", err);
          configStore.setModalMessage(
            "Server is not running. Please check browser console for specific error."
          );
        }
      });
  }
  static async fetch2(url, datacallback = (f) => f, errcallback = (f) => f) {
    let entity = "ALL";
    if (loginStore.isClient()) entity = configStore.currentSelectedEntity.entityId;
    const requestOptions = {
      headers: Api.H(),
    };
    await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        //console.log('Url: ' + url + ' Ret=', JSON.stringify(data))
        if (data.error) {
          if (data.error != "No Error" || data.error != "Invalid Token") {
            if (data.ajvErrors) {
              configStore.setModalMessage(data.error, data.ajvErrors);
            } else {
              configStore.setModalMessage(data.error);
            }
          } else {
            localStorage.removeItem("checkcount");
          }
        } else {
          // console.log("lkn" , data);
          datacallback(data);
        }
      })
      .catch((err) => {
        if (err != "No Error" || err != "Invalid Token") {
          console.log("Communication Error. Url: " + url + " Err=", err);
          configStore.setModalMessage(
            "Server is not running. Please check browser console for specific error."
          );
        }
      });
  }
  static postOrPut(method, url, payload, callback ,errCallback) {
    let entity = "ALL";
    if (loginStore.isClient()) entity = configStore.currentSelectedEntity.entityId;
    const requestOptions = {
      method: method,
      headers: Api.H(),
      body: JSON.stringify(payload),
    };
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        // console.log("Url: " + url + " Ret=", JSON.stringify(data) , "ll"-errCallback);
        if (data.error) {
          if (data.error != "No Error" || data.error != "Invalid Token") {
            if (data.ajvErrors) {
              configStore.setModalMessage(data.error, data.ajvErrors);
              if(errCallback){
                errCallback(false)
              }
            } else {
              if(data.error!=="Error: No recipients defined"){
                configStore.setModalMessage(data.error);
              }
              if (errCallback) {
                errCallback(false)
              }
            }
          }
        } else {
          callback(data);
        }
      })
      .catch((err) => {
        if (err != "No Error" || err != "Invalid Token") {
          console.log("Communication Error. Url: " + url + " Err=", err);
          configStore.setModalMessage(
            "Server is not running. Please check browser console for specific error."
          );
          if (errCallback){
            errCallback(false)
          }
        }
      });
  }
  static post(url, payload, callback = (f) => f, errCallback) {
    Api.postOrPut("POST", url, payload, callback, errCallback);
  }
  static put(url, payload, callback = (f) => f, errCallback) {
    console.log("put called ...");
    Api.postOrPut("PUT", url, payload, callback, errCallback);
  }

  //================================================================
  static loadCountryList() {
    fetch(GET_COUNTRYLIST_URI, { headers: Api.H() })
      .then((res) => res.json())
      .then((data) => {
        ServerLists.setCountryList(data);
        console.log("GOT LIST OF COUNTRIES =", ServerLists.countryList.length);
      })
      .catch((err) => {
        console.log("Url: " + GET_COUNTRYLIST_URI + " Err=", err);
      });
  }
  static getQuotes(callback = (f) => f) {
    Api.fetch(GET_QUOTES_URI, (data) => {
      quoteStore.setQuoteList(data);
      callback(data.quotesList);
    });
  }
  static getQuote(id) {
    Api.fetch(`${GET_QUOTE_URI}/${id}`, setCommonState);
  }

  static getretrunQuote(id, callback) {
    Api.fetch(`${GET_QUOTE_URI}/${id}`, callback);
  }

  static async quoteregenerate(id) {
    await Api.fetch(`${GET_QUOTE_URI}/regenerate/${id}`, (data) => {
      console.log(data);
      if (data.data.length > 0) {
        Api.fetch(`${GET_QUOTE_URI}/${data.data[0]}`, setCommonState);
        configStore.disableShipmentType = "EDIT";
        configStore.quoteCurrentView = "edit";
      }
    });
  }

  static cloneQuote(id) {
    Api.fetch(`${GET_QUOTE_URI}/${id}`, (quote) => {
      quote._id = undefined;
      quote.quoteData.status = "DRAFT";
      quote.quoteData.entityId = "";
      quote.quoteData.quotationNum = "";
      quote.quoteData.customerEmail = "";
      quote.quoteData.customerReferenceNum = "";
      quote.quoteData.sender = "";
      quote.quoteData.isFromEnquiry = false;
      quote.quoteData.cargoReadinessDate = "";
      quote.quoteData.validUpto = "";
      quote.quoteData.quoteStatus = "DRAFT";
      quote.status = "DRAFT";
      delete quote.decline_reason;
      delete quote.tsDeclineBy;
      delete quote.tsDeclineOn;
      delete quote.LostReason;
      delete quote.tsLostBy;
      delete quote.tsLostOn;
      delete quote.InvalidReason;
      delete quote.tsInvalidBy;
      delete quote.tsInvalidOn;
      delete quote.tsWonBy;
      delete quote.tsWonOn;
      delete quote.decline_reason;
      delete quote.quoteData.isfromAgragaEnquiry
      delete quote.quoteData.checkAgCustomer
      delete quote.quoteData.countryFromEnquiry
      delete quote._tbaid
      setCommonState(quote);
    });
  }

  static saveQuote(status) {
    console.log("INSIDE SAVE QUOTE");
    let chargesList;
    cargoStore.cargoList = cargoStore.cargoList.map(calcCargoDetails);
    cargoStore.calculateCargoTotals();
    if (quoteDataRec.shipmentType.indexOf("LCL") >= 0) {
      chargesStore1.calculateLCLChargesTotals();
      chargesList = chargesStore1.list;
    } else if (quoteDataRec.shipmentType.indexOf("FCL") >= 0) {
      fclChargesStore.calculateChargesTotals();
      chargesList = fclChargesStore.list;
    } else if (quoteDataRec.shipmentType.indexOf("Air") >= 0) {
      quoteDataRec.originAirport = quoteDataRec.portOfLoading;
      quoteDataRec.destinationAirport = quoteDataRec.portOfDischarge;
      airChargesStore.calculateChargesTotals();
      chargesList = airChargesStore.list;
    }
    console.log("chargesList = ", chargesList);
    quoteDataRec.sender = loginStore.userRec.email;
    console.log("sender = ", loginStore.userRec.email, quoteDataRec.sender);
    const e = quoteDataRec;
    e.portOfReceipt =
      e.portOfReceipt !== "" ? e.portOfReceipt : e.portOfLoading;
    e.portOfDischarge =
      e.portOfDischarge !== "" ? e.portOfDischarge : e.finalPlaceOfDelivery;
    const quoteSummary = {
      sender: e.sender,
      shipmentType: e.shipmentType,
      shipmentScope: e.shipmentScope,
      origin: e.origin,
      destination: e.destination,
      customerEmail: e.customerEmail,
      entityId: e.entityId,
    };
    console.log("INSIDE SAVING OF QUOTE ==============");
    console.log(cargoStore.totals);
    if(quoteDataRec.isfromAgragaEnquiry == 'Yes'){
      quoteDataRec.status = 'DRAFT'
    }
    let quoteDocument = {
      quoteSummary,
      quoteData: quoteDataRec,
      containerList: containerStore.list,
      cargoList: cargoStore.cargoList,
      cargoTotals: cargoStore.totals,
      chargesList: chargesList,
      status,
    };
    let S_Email = entityStore.getCustomerSEmail(quoteDataRec.gst)
    let C_Email = entityStore.getCustomerCEmail(quoteDataRec.gst)
    const getEntityName = (gst) => {
      let entity = entityStore.list.filter(e => e._id === gst)
      if (entity.length === 0) return gst
      return entity[0].entityName
  }
    console.log("contained in cargo totals", quoteDocument.cargoTotals);
    Api.post(POST_QUOTE_URI, quoteDocument, (data) => {
      quoteDataRec.quotationNum = data.quoteData.quotationNum;
      //console.log(JSON.stringify(data))
      if(data.quoteData.status !== 'DRAFT(Enquiry)'){
      // if(data.quoteData.isfromAgragaEnquiry != 'Yes'){
        configStore.setModalMessage(
          [<QuoteSavedMessage quotationRefNum={data.quoteData.quotationNum} />],
          [],
          false
        );
      }else{
        configStore.setModalMessage(
          [<QuoteSavedMessage1 quotationRefNum={data.quoteData.quotationNum} />],
          [],
          false
        );
      }
     
    });
  }
  static saveQuote1(status) {
    console.log("INSIDE SAVE QUOTE");
    let chargesList;
    cargoStore.cargoList = cargoStore.cargoList.map(calcCargoDetails);
    cargoStore.calculateCargoTotals();
    if (quoteDataRec.shipmentType.indexOf("LCL") >= 0) {
      chargesStore1.calculateLCLChargesTotals();
      chargesList = chargesStore1.list;
    } else if (quoteDataRec.shipmentType.indexOf("FCL") >= 0) {
      fclChargesStore.calculateChargesTotals();
      chargesList = fclChargesStore.list;
    } else if (quoteDataRec.shipmentType.indexOf("Air") >= 0) {
      quoteDataRec.originAirport = quoteDataRec.portOfLoading;
      quoteDataRec.destinationAirport = quoteDataRec.portOfDischarge;
      airChargesStore.calculateChargesTotals();
      chargesList = airChargesStore.list;
    }
    console.log("chargesList = ", chargesList);
    quoteDataRec.sender = loginStore.userRec.email;
    console.log("sender = ", loginStore.userRec.email, quoteDataRec.sender);
    const e = quoteDataRec;
    e.portOfReceipt =
      e.portOfReceipt !== "" ? e.portOfReceipt : e.portOfLoading;
    e.portOfDischarge =
      e.portOfDischarge !== "" ? e.portOfDischarge : e.finalPlaceOfDelivery;
    const quoteSummary = {
      sender: e.sender,
      shipmentType: e.shipmentType,
      shipmentScope: e.shipmentScope,
      origin: e.origin,
      destination: e.destination,
      customerEmail: e.customerEmail,
      gst: e.gst,
      entityId: e.entityId,
    };
    console.log("INSIDE SAVING OF QUOTE ==============");
    console.log(cargoStore.totals);
    
    
    let quoteDocument = {
      quoteSummary,
      quoteData: quoteDataRec,
      containerList: containerStore.list,
      cargoList: cargoStore.cargoList,
      cargoTotals: cargoStore.totals,
      chargesList: chargesList,
      status,
    };
    let S_Email = entityStore.getCustomerSEmail(quoteDataRec.gst)
    let C_Email = entityStore.getCustomerCEmail(quoteDataRec.gst)
    
    console.log(">>><><><><><",S_Email,C_Email)
    const getEntityName = (gst) => {
      let entity = entityStore.list.filter(e => e._id === gst)
      if (entity.length === 0) return gst
      return entity[0].entityName
  }
    EnquiriesApi.getEnquiry(e.quotationNum)
    console.log("contained in cargo totals", quoteDocument.cargoTotals);
    Api.post(POST_QUOTE_URI, quoteDocument, (data) => {
      quoteDataRec.quotationNum = data.quoteData.quotationNum;
      if(data.quoteData.status !== 'DRAFT(Enquiry)'){
        configStore.setModalMessage(
          [<QuoteSavedMessage quotationRefNum={data.quoteData.quotationNum} />],
          [],
          false
        );
      }else{
        configStore.setModalMessage(
          [<QuoteSavedMessage1 quotationRefNum={data.quoteData.quotationNum} />],
          [],
          false
        );
      }
     
    });
  }
  static saveQuote2(status) {
    console.log("INSIDE SAVE QUOTE");
    let chargesList;
    cargoStore.cargoList = cargoStore.cargoList.map(calcCargoDetails);
    cargoStore.calculateCargoTotals();
    if (quoteDataRec.shipmentType.indexOf("LCL") >= 0) {
      chargesStore1.calculateLCLChargesTotals();
      chargesList = chargesStore1.list;
    } else if (quoteDataRec.shipmentType.indexOf("FCL") >= 0) {
      fclChargesStore.calculateChargesTotals();
      chargesList = fclChargesStore.list;
    } else if (quoteDataRec.shipmentType.indexOf("Air") >= 0) {
      quoteDataRec.originAirport = quoteDataRec.portOfLoading;
      quoteDataRec.destinationAirport = quoteDataRec.portOfDischarge;
      airChargesStore.calculateChargesTotals();
      chargesList = airChargesStore.list;
    }
    console.log("chargesList = ", chargesList);
    quoteDataRec.sender = loginStore.userRec.email;
    console.log("sender = ", loginStore.userRec.email, quoteDataRec.sender);
    
    const e = quoteDataRec;
    e.portOfReceipt =
      e.portOfReceipt !== "" ? e.portOfReceipt : e.portOfLoading;
    e.portOfDischarge =
      e.portOfDischarge !== "" ? e.portOfDischarge : e.finalPlaceOfDelivery;
    const quoteSummary = {
      sender: e.sender,
      shipmentType: e.shipmentType,
      shipmentScope: e.shipmentScope,
      origin: e.origin,
      destination: e.destination,
      customerEmail: e.customerEmail,
      gst: e.gst,
      entityId:e.entityId
    };
    console.log("INSIDE SAVING OF QUOTE ==============");
    EnquiriesApi.getEnquiry(e.quotationNum)
    console.log("::::::::::::::::::::::::",enquiryStore.current.tsQuoteGeneratedOn,enquiryStore.current.tsCreatedOn)
    console.log(cargoStore.totals);
    console.log("TTTTTTTTTTTTTTTTTTTTTtt",Utils.getDateDifference(enquiryStore.current.tsCreatedOn,enquiryStore.current.tsCreatedOn).hours,Utils.getDateDifference(enquiryStore.current.tsCreatedOn,enquiryStore.current.tsCreatedOn).minutes,)
    
    if(quoteDataRec.isfromAgragaEnquiry == 'Yes'){
      quoteDataRec.status = 'DRAFT'
    }
    let quoteDocument = {
      quoteSummary,
      quoteData: quoteDataRec,
      containerList: containerStore.list,
      cargoList: cargoStore.cargoList,
      cargoTotals: cargoStore.totals,
      chargesList: chargesList,
      status,
    };
    let S_Email = entityStore.getCustomerSEmail(quoteDataRec.gst)
    let C_Email = entityStore.getCustomerCEmail(quoteDataRec.gst)
    
    console.log(">>><><><><><",S_Email,C_Email)
    const getEntityName = (gst) => {
      let entity = entityStore.list.filter(e => e._id === gst)
      if (entity.length === 0) return gst
      return entity[0].entityName
  }
  let CustomerName;
  

    console.log("contained in cargo totals", quoteDocument.cargoTotals);
    Api.post(POST_QUOTE_URI, quoteDocument, (data) => {
      console.log(enquiryStore,'enquiryStore..');
      quoteDataRec.quotationNum = data.quoteData.quotationNum;
      let hr = Utils.getDateDifference(Number(data.tsUpdatedOn),Number(enquiryStore.current.tsCreatedOn)).hours
      let mm = Utils.getDateDifference(Number(data.tsUpdatedOn),Number(enquiryStore.current.tsCreatedOn)).minutes
      let to
      if(data.quoteData.entityId === "TBA"){
      // if(data.quoteData.gst == 'GSTTBA000000000'){
        to = `${enquiryStore.current.tsCreatedBy}`;
        CustomerName = 'Yet To Be Assigned'
      }else{
        // to = `${S_Email} ,${C_Email}`;
        // CustomerName = entityStore.getCustomer4gstOnly(quoteDataRec.gst)
        EntityApi.getEntity(e.entityId,entityData=>{
          to = getRespectiveMember(entityData.customer)
          CustomerName = entityData.entityName
        })
      }
        setTimeout(()=>{
        let from = `Agraga <${process.env.REACT_APP_SUPPORTMAIL_ID}>`
         let subject = 'Quotation Creation - '
         let message = `Hi Team,<br />
         <br />
         &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;       Quotation for the required enquiry has been created.<br />
         &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;       Quotation ID : ${data.quoteData.quotationNum}.<br />
         &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;       Entity Name :${CustomerName}.<br />
         &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;       Origin: ${e.origin}.<br />
         &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;        Destination: ${e.destination}.<br />
         &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;       Shipment Type: ${e.shipmentType}.<br />
         &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;       Scope : ${e.shipmentScope}.<br />
         &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;       Enquiry Created On : ${Utils.formatToDateTime(Number(enquiryStore.current.tsCreatedOn))}.<br />
         &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;       Enquiry Created By : ${enquiryStore.current.tsCreatedBy}.<br />
         &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;       Quotation Created On : ${Utils.formatToDateTime(Number(data.tsUpdatedOn))}.<br />
         &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;       Quotation Created By : ${data.tsUpdatedBy}.<br />
         
         <br />
         This Quotation has been created with in ${(hr > 0) ? hr : "0"} hrs:${(mm > 0) ? mm : "0"}minutes.
         <br/>
          A quotation for the required enquiry has been created. Please <a href=${window.location.href}quotation/edit/${data.quoteData.quotationNum} target="_blank">[Click Here]</a> to access them.
         <br/>
         Team Agraga<br />`
            Api.sendClientMailwithoutMessage(from, to,subject, message)
        }, 3000)
        

        configStore.setModalMessage(
          [<QuoteSavedMessage quotationRefNum={data.quoteData.quotationNum} />],
          [],
          false
        );
  
    });
  }
  static quoteAccountTransformHelper(quote) {
    let q = JSON.parse(JSON.stringify(quote))
    /*console.log('\n\n\n###################\nCHARGES LENGTHS=',
      q.chargesList.length, q.shipperchargesList.length, q.consigneechargesList.length, '\n##########'
    )*/
    for (let i = 0; i < q.chargesList.length; i++) {
      let e = q.chargesList[i]
      e.onaccount = 'On Your Account'
    }
    if (q.shipperchargesList && q.shipperchargesList.length) {
      for (let i = 0; i < q.shipperchargesList.length; i++) {
        let e = q.shipperchargesList[i]
        e.onaccount = `Shipper's Account`
        q.chargesList.push(e)
      }
    }
    if (q.consigneechargesList && q.consigneechargesList.length) {
      for (let i = 0; i < q.consigneechargesList.length; i++) {
        let e = q.consigneechargesList[i]
        e.onaccount = `Consignee's Account`
        q.chargesList.push(e)
      }

    }
    return q
  }
  static saveQuoteNoTransform(rec) {
    let quoteDocument = Api.quoteAccountTransformHelper(rec.quote)
    let airline = airlineCodes.find(e => e.code == rec.iata)
    if (airline) {
      quoteDocument.quoteSummary.shipmentType = 'Air'
      quoteDocument.quoteData.airline = airline.name
    } else if (quoteDocument.quoteData.shipmentType.startsWith('FCL')) {
      quoteDocument.quoteSummary.shipmentType = quoteDocument.quoteData.shipmentType
      //quoteDocument.quoteData.origin = quoteDocument.quoteSummary.origin
    }
    quoteDocument.status = 'DRAFT'
    console.log("INSIDE SAVE QUOTE");
    console.log("\n\n\n\n\nSSSSSSSSSSSSSSSSSSS",quoteDocument ,  quoteDocument.quoteSummary.shipmentType, quoteDocument.quoteData.shipmentType,quoteDocument.quoteData.origin);
    Api.post(POST_QUOTE_URI, quoteDocument, (data) => {
      quoteDataRec.quotationNum = data.quoteData.quotationNum;
      let schedules = [...rec.dummyContract.schedules]
      for (let i = 0; i < schedules.length; i++) {
        schedules[i].fromPricing = 'true'
      }
      if (schedules && schedules.length) {
        MonApi.insertOne('Schedules', {
          _id: data.quoteData.quotationNum + 'C',
          schedules: schedules
        })
      }
      //console.log(JSON.stringify(data))
      configStore.setModalMessage(
        [<PricingQuoteSaved quotationRefNum={data.quoteData.quotationNum} />],
        [],
        false
      );
    });
  }
  static async saveQuoteAndContract(rec, isDummy) {
    let quoteDocument = Api.quoteAccountTransformHelper(rec.quote)
    let airline = airlineCodes.find(e => e.code == rec.iata)
    if (airline) {
      quoteDocument.quoteSummary.shipmentType = 'Air'
      quoteDocument.quoteData.airline = airline.name
    }
    quoteDocument.status = 'DRAFT'
    console.log("INSIDE SAVE QUOTE AND Contract");
    console.log("contained in cargo totals", quoteDocument.cargoTotals)
    let data = await MonApi.apost(POST_QUOTE_URI, quoteDocument)

    let ret = await MonApi.apost(`${process.env.REACT_APP_API_SERVER}/api/v1/quotes/finalize/quote/for/pricing`,
      { quoteNum: data.quoteData.quotationNum })
    if (isDummy) {
      await MonApi.updateOne('Contracts', { _id: ret.contract._id }, { hasToShowDummySchedule: true })
    }
    let schedules = [...rec.dummyContract.schedules]
    for (let i = 0; i < schedules.length; i++) {
      schedules[i].fromPricing = 'true'
    }
    if (schedules && schedules.length) {
      await MonApi.insertOne('Schedules', {
        _id: ret.contract._id,
        schedules: schedules
      })
    }
    console.log('\n\n\n\n\nRETURN FROM CONTACT=', ret)
    configStore.setModalMessage(
      [<PricingContractSaved contractNum={ret.contract._id} />],
      [],
      false
    );
  }
  static async sendMailCommon(url) {
    // assumed to be invoked only from QuoteViewMain
    configStore.setModalMessage("Sending Email ...");
    const agUserList = await AguserApi.getAguserList();
    console.log(toEmailStore.emails , ccEmailStore.emails );
    let checkedToMailIds = toEmailStore.emails.filter(
      (email) =>  
        Utils.isEmail(email) &&
        (email.split("@")[1] === "agraga.com" ||
          agUserList.map(({ email }) => email).includes(email))
    );
    let checkedCCMailIds = ccEmailStore.emails.filter(
      (email) =>
        Utils.isEmail(email) &&
        (email.split("@")[1] === "agraga.com" ||
          agUserList.map(({ email }) => email).includes(email))
    );
    checkedCCMailIds.push(EntityV2Store.EntityRec.customer.crossBorder.relationshipManager)
    let quoteTemplate = ReactDOMServer.renderToString(
      <QuoteViewv2 isMailBody={true} />
    );
    quoteTemplate = quoteTemplate.replace(
      "Sales Person",
      "Relationship Manager"
    );
    console.log();
    const emailDocument = {
      fromEmail : EntityV2Store.EntityRec.customer.crossBorder.customerService,
      quoteNum: quoteDataRec.quotationNum,
      toEmails: checkedToMailIds,
      ccEmails: checkedCCMailIds,
      reqdata: quoteDataRec.reqdata,
      quoteTemplate,
    };
    if (checkedToMailIds.length === 0) {
      configStore.setModalMessage(`No Valid EmailIds Found.`);
      return;
    }
    console.log(emailDocument);
    Api.post(url, emailDocument, (data) => {
      configStore.setModalMessage(`Email sent to ${data.message.accepted}`);
      configStore.quoteCurrentView = 'list'
    });
    configStore.quoteCurrentView = 'list'
  }
  static async sendMail2Common(url) {
    // assumed to be invoked only from QuoteViewMain
   
    console.log("Sending Email ...");
    // const agUserList = await AguserApi.getAguserList();
    let checkedToMailIds = toEmailStore.emails
    // toEmailStore.emails.filter(
    //   (email) =>
    //     Utils.isEmail(email) &&
    //     (email.split("@")[1] === "agraga.com" ||
    //       agUserList.map(({ email }) => email).includes(email))
    // );
    
    
    let checkedCCMailIds = ccEmailStore.emails
    // ccEmailStore.emails.filter(
    //   (email) =>
    //     Utils.isEmail(email) &&
    //     (email.split("@")[1] === "agraga.com" ||
    //       agUserList.map(({ email }) => email).includes(email))
    // );
    let quoteTemplate = ReactDOMServer.renderToString(
      <QuoteViewv2 isMailBody={true} />
    );
    console.log(quoteTemplate,'quoteTemplate');
    quoteTemplate = quoteTemplate.replace(
      "Customer:",
      ""
    )
    quoteTemplate = quoteTemplate.replace(/YET TO BE ASSIGNED/g, "");
    quoteTemplate = quoteTemplate.replace(
      "Sales Person",
      "Relationship Manager"
    );
    console.log();
    const emailDocument = {
      quoteNum: quoteDataRec.quotationNum,
      toEmails: checkedToMailIds,
      ccEmails: checkedCCMailIds,
      reqdata: quoteDataRec.reqdata,
      quoteTemplate,
    };
    if (checkedToMailIds.length === 0) {
      configStore.setModalMessage(`No Valid EmailIds Found.`);
      return;
    }
    Api.post(url, emailDocument, (data) => {
      configStore.setModalMessage(`Email sent to ${data.message.accepted}`);
      configStore.quoteCurrentView = 'list'
    });
    configStore.quoteCurrentView = 'list'
  }
  static sendMail() {
    // assumed to be invoked only from QuoteViewMain
    Api.sendMailCommon(POST_EMAIL_URI);
  }
  static send2Mail() {
    // assumed to be invoked only from QuoteViewMain
    Api.sendMailCommon(POST_EMAIL2_URI);
  }
  static send3Mail() {
    // assumed to be invoked only from QuoteViewMain
    Api.sendMail2Common(POST_EMAIL3_URI);
  }
  static sendMailwithPDF() {
    Api.sendMailCommon(POST_EMAILWITHPDF_URI);
  }

  static getContracts(callback = (f) => f) {
    Api.fetch(GET_CONTRACTS_URI, (data) => {
      console.log("GOT CONTRACTs DATA=", data.length);
      contractsStore.list = data;
      callback(data)
    });
  }

  static getContract(id, callback = (f) => f) {
    Api.fetch(GET_CONTRACT_URI(id), (data) => {
      console.log("GOT CONTRACT DATA=", data._id);
      data = new QuoteCalcV2(data).recalc();
      console.log("AFTER CALCULATION=", data.quoteValue, data.quoteValueUSD);
      contractsStore.current = data;
      callback(data);
    });
  }
  static getContractv2(id, callback = (f) => f) {
    Api.fetch(GET_CONTRACT_URI(id), (data) => {
      console.log("GOT CONTRACT DATA=", data._id);
      data = new QuoteCalcV2(data).recalc();
      console.log("AFTER CALCULATION=", data.quoteValue, data.quoteValueUSD);
      contractsStore.current = data;
      callback(data);
    });
  }

  static getlastupload(type, callback = (f) => f) {
    Api.fetch(LASTUPLOAD(type), (data) => {
      callback(data);
    });
  }

  static setWonquote(id, callback = (f) => f) {
    Api.post(WON_QUOTES(id), {}, (data) => {
      callback(data);
    });
  }
  static updateQuote(id,callback = (f) => f) {
    Api.fetch(GET_ENQUIRYSTATUS_URI(id), (quote) => {
      console.log(">>>SDDATA",quote)
      callback(quote)
    });
  }
  static setAgragaEnquiryquote(id, callback = (f) => f) {
    Api.post(AGRAGA_ENQUIRY_STATUS(id), {}, (data) => {
      callback(data);
    });
  }
  static setLostQuote(id, reason, callback = (f) => f) {
    Api.post(LOST_QUOTES(id), reason, (data) => {
      callback(data);
    });
  }
  static setInvalidQuote(id, reason, callback = (f) => f) {
    Api.post(INVALID_QUOTES(id), reason, (data) => {
      callback(data);
    });
  }

  static getMergedContract(callback = (f) => f) {
    console.log("ABOUT TO CALL MERGED CONTRACT");
    Api.post(GET_BEST_MATCH4ENQUIRY, enquiryStore.current, (data) => {
      if (!data._id) {
        contractsStore.current = data;
        callback(data);
        configStore.displaySchedules = false;
        return;
      }
      console.log("======================");
      console.log(data._id);
      let newdata = enquiryStore.current.mergeIntoContract(data);
      console.log(
        "AFTER CALCULATION=",
        newdata.quoteValue,
        newdata.quoteValueUSD
      );
      contractsStore.current = newdata;
      callback(newdata);
    });
  }
  static createContractFromQuote(id, callback = (f) => f) {
    console.log("ABOUT TO CALL createContractFromQuote");
    Api.post(CREATE_CONTRACT_FROM_QUOTE_URI(id), {}, (data) => {
      console.log("GOT CONTRACT DATA=", data._id);
      callback(data);
    });
  }
  static declineContract(id, reason, callback = (f) => f) {
    console.log("ABOUT TO CALL createContractFromQuote");
    console.log("ABOUT",reason)
    Api.post(DECLINE_CONTRACT(id), reason, (data) => {
      console.log("GOT CONTRACT DATA=", data);
      console.log("GOT CONTRACT DATA=", contractsStore.current);
      if(contractsStore.current.isfromAgragaEnquiry == 'Yes'){
        let S_Email = entityStore.getCustomerSEmail(contractsStore.current.gst)
        let C_Email = entityStore.getCustomerCEmail(contractsStore.current.gst)
        // if(rec.isfromAgragaEnquiry == 'Yes' && rec.gst == 'GSTTBA000000000'){
        //     S_Email = rec.createdBy
        //     C_Email = rec.createdBy
        // }else{
            // S_Email = entityStore.getCustomerSEmail(rec.gst)
            // C_Email = entityStore.getCustomerCEmail(rec.gst)
        // }
        // Api.post(ENQUIRIESURI.POST, enquiryStore.current, (data) => {
        //     console.log("QQQQQ",enquiryStore.current)
            setTimeout(()=>{
                let from = `Agraga <${process.env.REACT_APP_SUPPORTMAIL_ID}>`
                let to = `${S_Email} ,${C_Email}`;
                let subject = 'CONTRACT - '+contractsStore.current._id
                let message = `Hi Team,<br />
                <br />
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;        Contract Number:${contractsStore.current._id}.<br />
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;       Shipment Type: ${contractsStore.current.shipmentType}.<br />
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;       Scope : ${contractsStore.current.shipmentScope}.<br />
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;       Origin: ${contractsStore.current.origin}.<br />
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;        Destination: ${contractsStore.current.destination}.<br />
                The Quotation created submitted by the customer has been rejected for the below reason: ${reason.reason}.<br />
                <br />
                Team Agraga<br />`
    
                Api.sendClientMailwithoutMessage1(from, to, subject, message)
            }, 8000)
            // if(callback){
            //     callback();
            // }
            // setTimeout(() => {
            //     configStore.setEnquiryModalMessage(data._id)
            // }, 5000);
                
            
        // })
    }
      callback(data);
    });
  }
  static acquireContractLock(id, callback = (f) => f) {
    console.log("ABOUT TO CALL createContractFromQuote");
    Api.post(CREATE_CONTRACT_FROM_QUOTE_URI(id), {}, (data) => {
      console.log("GOT CONTRACT DATA=", data._id);
      callback(data);
    });
  }
  static sendClientMail(from, to, subject, message, modalMessagePrefix = "") {
    // configStore.setModalMessage("Sending Email ...");
    const doc = {
      from,
      to,
      subject,
      message,
    };
    return Api.post(SEND_CLIENTMAIL_URI, doc, (data) => {
      // configStore.setModalMessage(
      //   `${modalMessagePrefix} Email sent to ${data.message.accepted}`
      // );
      routerStore.agusersCurrentView ="list"
    });
  }

  static sendClientMailwithoutMessage(
    from,
    to,
    subject,
    message,
    cc
  ) {
    const doc = {
      from,
      to,
      cc,
      subject,
      message,
    };
    Api.post(SEND_CLIENTMAIL_URI, doc, (data) => {
      console.log(data);
    });
  }
  static sendClientMailwithoutMessage1(
    from,
    to,
    subject,
    message,
    modalMessagePrefix = ""
  ) {
    const doc = {
      from,
      to,
      subject,
      message,
    };
    Api.post(SEND_CLIENTMAIL_URI1, doc, (data) => {
      console.log(data);
    });
  }



  static isEmailValid(email, callback) {
    Api.fetch(`${VALIDATE_EMAIL_URI}/${email}`, (data) => {
      let isValid = false;
      if (data.custId) {
        if (parseInt(data.custId) > 0) {
          isValid = true;
        }
      }
      callback(isValid);
    });
  }
  static async getUser(email, password, callback = (f) => f) {
    Api.fetch(`${GET_USER4EMAILPASSWORD}/${email}/${password}`, (user) => {
      console.log("Got user = ", user);
      loginStore.userRec = user;
      console.log("value of userRec=", loginStore.userRec);
      callback(user);
      if (!user.userFound)
        configStore.setModalMessage("Invalid username/password");
    });
  }
  static updateQuoteStatus(id, status, callback) {
    const url = `${UPDATE_QUOTE_STATUS}/${id}/${status}`;
    fetch(url, { headers: Api.H() })
      .then((res) => res.json())
      .then((data) => {
        callback(data);
      })
      .catch((err) => {
        console.log("Url: " + url + " Err=", err);
        callback({ error: "" + err });
      });
  }
  static getCustomers() {
    //console.log('inside getCustomers')
    fetch(GET_CUSTOMERS_URI, { headers: Api.H() })
      .then((res) => res.json())
      .then((data) => {
        let listData = data.map((e) => {
          return {
            key: e.gst,
            value: e.entityName,
          };
        });
        listData = ["", ...listData];
        customerStore.list = listData;
        //console.log('Customer list1=', JSON.stringify(customerStore.list))
      })
      .catch((err) => {
        console.log("Url: " + GET_CUSTOMERS_URI + " Err=", err);
      });
  }
  static getUnloc(prefix, callback) {
    prefix?.trim();
    if (prefix.length < 3) {
      callback([]);
      return;
    }
    fetch(`${GET_UNLOC_URI}/${prefix}`, { headers: Api.H() })
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data)) {
          callback(data);
          return;
        }
        throw new Error(data["error"] || data.toString());
      })
      .catch((err) => {
        console.log("Url: " + GET_UNLOC_URI + " Err=", err);
        callback([]);
      });
  }

  static getMapUnloc(data, callback) {
    Api.post(GET_MAP_UNLOC_URI, data, (result) => {
      callback(result);
    });
  }
  static get4Unloc(unloc, callback) {
    fetch(`${GET_4UNLOC_URI}/${unloc}`, { headers: Api.H() })
      .then((res) => res.json())
      .then((data) => {
        callback(data);
      })
      .catch((err) => {
        console.log("Url: " + GET_UNLOC_URI + " Err=", err);
        callback({});
      });
  }
  static async getExchangerates() {
    try {
      const requestOptions = {
        headers: Api.H(),
      };
      let res = await fetch(`${GET_EXCHANGERATES_URI}`, requestOptions)
      let data = await res.json();
      if (data.error) {
        console.log(data.error)
      } else {
        data.unshift({
          currency: "INR",
          exchangeRate: 1.0,
        })
        if(JSON.stringify(configStore.currencyList)!=JSON.stringify(data)){
          configStore.currencyList = data;
        }
      }
    } catch (err) {
      console.log("Url: " + GET_EXCHANGERATES_URI + " Err=", err);
      configStore.currencyList = ["INR"];
    }
  }
  static getLiveExchangerates(callback) {
    fetch(`${GET_EXCHANGERATES_URI}`, { headers: Api.H() })
      .then((res) => res.json())
      .then((data) => {
        configStore.currencyList = data;
        configStore.currencyList.unshift({
          currency: "INR",
          exchangeRate: 1.0,
        });
        callback(data)
      })
      .catch((err) => {
        console.log("Url: " + GET_EXCHANGERATES_URI + " Err=", err);
        configStore.currencyList = ["INR"];
      });
  }
  static addCustomer(entityName, gst) {
    let customer = {
      entityName,
      gst,
    };
    Api.post(CREATE_CUSTOMER_URI, customer, (data) => {
      Api.getCustomers();
      configStore.setModalMessage("Customer Added");
    });
  }

  static addTrackBookingsAPI() {
    fetch(TRACK_BOOKINGS_URI, { headers: Api.H() })
      .then((res) => res.json())
      .then((data) => {
        trackMyBookingOceanStore.setTrackBooking()
      })
      .catch((err) => {
        console.log("Url: " + + " Err=", err);
      });
  }

  static addTrackBookings(bookings, type, callBack, search) {
    bookings.type = type
    bookings.entityId = configStore.currentSelectedEntity._id;
    bookings.branchId = configStore.currentSelectedBranch._id
    Api.post(TRACK_BOOKINGS_URI, bookings, (data) => {
      Api.addTrackBookingsAPI()
      if (data.status === 'Failed') {
        configStore.setModalMessage(data.msg)
        search(data)
      } else {
        callBack()
        configStore.setModalMessage(data.msg);
      }
    });
  }

  static async checkDriverStatus(phnumber) {
    try {
      let header = Api.H()
      header['Content-Type'] = 'application/pdf'
      header['Accept'] = '*/*'
      let response = await fetch(CHECK_DRIVER_STATUS_URI + `${phnumber}`, { method: "GET", headers: header })
      return await response.json()
    } catch (error) {

    }
  }

  static async endTrip(bookingId, tripId) {
    try {
      let header = Api.H()
      header['Content-Type'] = 'application/pdf'
      header['Accept'] = '*/*'
      let response = await fetch(END_TRIP_URI + `${bookingId}/${tripId}`, { method: "GET", headers: header })
      const data = await response.json()
      console.log("endTripResponse: " + data);
      configStore.modalMessage(data)
    } catch (error) {

    }
  }

  static getFCLCarrierList(callback = (result) => result) {
    fetch(`${GET_FCL_CARRIER_LIST_URI}`, { headers: Api.H() })
      .then((res) => res.json())
      .then((data) => {
        dataListStore["FCLCarrierList"] = data;
      })
      .catch((err) => {
        console.log("Url: " + GET_UNLOC_URI + " Err=", err);
        callback([]);
      });
  }

  static getFCLCarrierListspot(callback) {
    fetch(`${GET_FCL_CARRIER_LIST_URI}`, { headers: Api.H() })
      .then((res) => res.json())
      .then((data) => {
        callback(data)
      })
      .catch((err) => {
        console.log("Url: " + GET_UNLOC_URI + " Err=", err);
        callback([]);
      });
  }

  static getP44FCLCarrierList(callback = (result) => result) {
    fetch(`${GET_P44_FCL_CARRIER_LIST_URI}`, { headers: Api.H() })
      .then((res) => res.json())
      .then((data) => {
        dataListStore.P44CarrierList = data;
      })
      .catch((err) => {
        console.log("Url: " + GET_UNLOC_URI + " Err=", err);
        callback([]);
      });
  }


  static async getQuotationDocumentById(quoteNum) {
    let isFailed = false;
    let header = Api.H()
    header['Content-Type'] = 'application/pdf'
    header['Accept'] = '*/*'
    try {
      let a = await fetch(GET_QUOTATION_DOCUMENT_URI(quoteNum), { method: "GET", headers: header })
      a = await a.json()
      return a.data
    } catch (err) {
      configStore.setModalMessage(err?.error || err.toString())
    }
  }

  static async getQuotationDocumentByIdv2(quoteNum) {
    let isFailed = false;
    let header = Api.H()
    header['Content-Type'] = 'application/pdf'
    header['Accept'] = '*/*'
    try {
      let a = await fetch(GET_QUOTATION_DOCUMENT_URI1(quoteNum), { method: "GET", headers: header })
      a = await a.json()
      return a.data
    } catch (err) {
      configStore.setModalMessage(err?.error || err.toString())
    }
  }
  static async getQuotationDocumentByIdv3(quoteNum) {
    let isFailed = false;
    let header = Api.H()
    header['Content-Type'] = 'application/pdf'
    header['Accept'] = '*/*'
    try {
      let a = await fetch(GET_QUOTATION_DOCUMENT_URI2(quoteNum), { method: "GET", headers: header })
      a = await a.json()
      return a.data
    } catch (err) {
      configStore.setModalMessage(err?.error || err.toString())
    }
  }
  static async getCafDocumentById(CafId) {
    let isFailed = false;
    let header = Api.H()
    header['Content-Type'] = 'application/pdf'
    header['Accept'] = '*/*'
    try {
      let a = await fetch(GET_CAF_DOC_URI(CafId), { method: "GET", headers: header })
      a = await a.json()
      return a.data
    } catch (err) {
      configStore.setModalMessage(err?.error || err.toString())
    }
  }
  static async getCafFormDownload(CafId) {
    let isFailed = false;
    let header = Api.H()
    header['Content-Type'] = 'application/pdf'
    header['Accept'] = '*/*'
    try {
      let a = await fetch(GET_CAF_FORM_URI(CafId), { method: "GET", headers: header })
      a = await a.json()
      return a.data
    } catch (err) {
      configStore.setModalMessage(err?.error || err.toString())
    }
  }
  static async importQuotationsFromSheet() {
    const body = new FormData();
    body.append("file", quotatationImportStore.quotationSheetFile);
    body.append("entity", quotatationImportStore.entity);
    body.append("shipmentType", quotatationImportStore.selectedShipmentType);
    fetch(QUOTATIONS_SHEET_IMPORT_URI, {
      headers: { ...Api.H1() },
      method: "POST",
      body,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result?.error) throw result;
        configStore.setModalMessage(result?.message || result.toString());
        quotatationImportStore.reset();
        Api.getQuotes();
      })
      .catch((err) => {
        configStore.setModalMessage(err?.error || err.toString());
      });
  }

  static async deleteQuote(quoteNum) {
    fetch(DELETE_QUOTE_URI(quoteNum), {
      method: "DELETE",
      headers: { ...Api.H1() },
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.count > 0) {
          configStore.setModalMessage(`${quoteNum} got deleted successfully`);
        } else throw result;
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: Api.js ~ line 432 ~ Api ~ deleteQuote ~ error",
          error
        );
        configStore.setModalMessage(`Unable to delete quote ${quoteNum}`);
      });
  }


  static async updateCustomerReferenceId(objectId, customerReferenceId) {
    try {
      const data = {
        objectId: objectId,
        customerReferenceId: customerReferenceId
      }
      const response = await fetch(UPDATE_CUSTOMER_REFERENCE_URI, {
        method: "POST",
        headers: { ...Api.H1(), "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
      const message = await response.json()
      configStore.setModalMessage(message)
    } catch (error) {
      configStore.setModalMessage(
        `Unable to update Customer Reference ID`
      );
    }
  }


  static async updateDocumentById(objectId, files,str) {
    try {
      const data = {
        objectId: objectId,
        files:files
      }
      const response = await fetch(UPDATE_DOCUMENT_URI, {
        method: "POST",
        headers: { ...Api.H1(), "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
      const message = await response.json()
      if(str==='del'){
        configStore.setModalMessage('File Deleted Successfully')
      }else{
        configStore.setModalMessage(message)
      }
    } catch (error) {
      configStore.setModalMessage(
        `Unable to update files`
      );
    }
  }

  static async deleteBookings(bookingId,callback) {
    try {
      let header = Api.H()
      header['Content-Type'] = 'application/pdf'
      header['Accept'] = '*/*'
      let response = await fetch(DELETE_BOOKING_URI + `${bookingId}`, { method: "GET", headers: header })
      const message = await response.json()
      callback()
      configStore.setModalMessage(message)
    } catch (error) {
      configStore.setModalMessage(
        `Unable to Delete Booking: ${error.message}`
      );
    }
  }

  static async updatePhoneNumber(bookingId, phoneNumber, tripId) {
    try {
      const data = {
        bookingId: bookingId,
        drivernumber: phoneNumber,
        tripId: tripId
      }
      const response = await fetch(UPDATE_PHONE_NUMBER_URI, {
        method: "POST",
        headers: { ...Api.H1(), "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
      const message = await response.json()
      configStore.setModalMessage(message)
    } catch (error) {
      configStore.setModalMessage(
        `Unable to update Phone Number: ${error.message}`
      );
    }
  }

  static async updateOutCome(quoteNum, outCome, callBack) {
    // Api.put(UPDATE_OUTCOME_STATUS_URI(quoteNum), {outCome}, (result) => {
    //     if(result?.modifiedCount > 0) configStore.setModalMessage(`Outcome Status Update successfully`);
    //     else throw result;
    // })
    fetch(UPDATE_OUTCOME_STATUS_URI(quoteNum), {
      method: "PUT",
      headers: { ...Api.H1(), "Content-Type": "application/json" },
      body: JSON.stringify({ outCome }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result?.modifiedCount > 0) {
          configStore.setModalMessage(`Outcome Status Update successfully`);
          callBack(result);
        } else throw result;
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: Api.js ~ line 465 ~ Api ~ updateOutCome ~ error",
          error
        );
        configStore.setModalMessage(
          `Unable to update quote outcome status ${quoteNum}`
        );
      });
  }

  static async getSchedulesList(quoteNum, resdata, callback = (f) => f) {
    Api.post(SCHEDULES_CONTRACT(quoteNum), resdata, (data) => {
      callback(data);
    });
  }

  static checkUnloc(unloc, callback = (f) => f) {
    Api.post(CHECKUNLOC, { unloc }, (d) => {
      callback(d);
    });
  }

  static async getmenucount() {
    localStorage.setItem("checkcount", "1");
    await Api.fetch1(MENUCOUNT, (data) => {
      menucountStore.setcount(data);
      localStorage.removeItem("checkcount");
    });
  }

  static getSchedulesLCLURL() {
    return GETLCLSCHEDULESMASTER;
  }

  static apieditdsr(payload, callback = (f) => f) {
    Api.post(EDITDSR, payload, (data) => {
      callback(data);
    });
  }

  static getproject44data(id, callback = (f) => f) {
    Api.fetch(GETPROJECT44(id), (data) => {
      callback(data);
    });
  }
  static getintermodata(id, callback = (f) => f) {
    Api.fetch(GETINTERMO(id), (data) => {
      callback(data);
    });
  }
  static getproject44BookingsData(id, callback = (f) => f, refresh) {
    Api.fetch(GETPROJECT44ID(id), (data) => {
      callback(data);
    });
  }

  static getIntermoBookingsData(id, callback = (f) => f, refresh) {
    Api.fetch(GETINTERMOID(id), (data) => {
      callback(data);
    });
  }


  // ============================ Approval Api =============================
  static async approval_API(url, datacallback = (f) => f, errcallback = (f) => f) {
    let entity = "ALL";
    if (loginStore.isClient()) entity = configStore.currentSelectedEntity.gst;
    const requestOptions = {
      headers: Api.H(),
    };
    await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        //console.log('Url: ' + url + ' Ret=', JSON.stringify(data))
        if (data.error) {
          if (data.error != "No Error" || data.error != "Invalid Token") {
            if (data.ajvErrors) {
              configStore.setModalMessage(data.error, data.ajvErrors);
            } else {
              configStore.setModalMessage(data.error);
            }
          } else {
            localStorage.removeItem("checkcount");
          }
        } else {
          datacallback(data);
        }
      })
      .catch((err) => {
        if (err != "No Error" || err != "Invalid Token") {
          console.log("Communication Error. Url: " + url + " Err=", err);
          configStore.setModalMessage(
            "Server is not running. Please check browser console for specific error."
          );
        }
      });
  }
}



