import { proxy } from "valtio";
import { devtools } from'valtio/utils'
import {
  Quotation,
  CargoDetails,
  ContainerDetails,
  QuoteCharges,
  FCLQuoteCharges,
  Emails,
  QuoteCharges1,
  AirQuoteCharges,
  AguserType,
  EntityType,
  VehicleType,
  DriverType,
  BranchType,
  CreditAappFormType,
  EntityTypeV2,
  CreditFormType,
  CustomeruserType,
  ApprovalTab,
} from "./Types";

import TC from "../TC";
import ListQuote from "../ListQuote";
import QuoteMain from "../QuoteMain";
import Dashboard from "../components/dashboard";
import Utils from "../util/Utils";
import {
  chargesValidationStore,
  QuotationValidationStore,
} from "./validation-stores";
import { watch } from "valtio/utils";
import { calcCargoDetails } from "../CargoComp";
import HomeMain from "../enquiry/home-main";
import { enquiryStore } from "../enquiry/enquiry-data";

let aguserStore = proxy({
  list: [],
  auditList: [],
  aguserRec: new AguserType(),
  getName4email: (email) =>
    aguserStore.list
      .filter((e) => e.email === email)
      .map((e) => `${e.firstName} ${e.lastName}`)[0],
});
let cususerStore = proxy({
  list: [],
  cususerRec: new CustomeruserType()
});
let entityStore = proxy({
  list: [],
  entityRec: new EntityType(),
  getCustomer4gst: (gst) => {
    return entityStore.list
      .filter((e) => e.gst === gst)
      .map((e) => `${e.entityName} ${(e.state == undefined || e.state == '') ? "" : e.state}`);
  },
  getCustomer4gstOnly: (gst) => {
    return entityStore.list
      .filter((e) => e.gst === gst)
      .map((e) => `${e.entityName}`);
  },
  getCustomerSEmail: (gst) => {
    return entityStore.list
      .filter((e) => e.gst === gst)
      .map((e) => `${e.salesPerson}`);
  },
  getCustomerCEmail: (gst) => {
    return entityStore.list
      .filter((e) => e.gst === gst)
      .map((e) => `${e.customerService}`);
  },
});

let vehicleStore = proxy({
  list: [],
  vehicleRec: new VehicleType(),
});

let DriverStore = proxy({
  list: [],
  DriversRec: new DriverType(),
});
let ApprovalTabStore = proxy({
  ApprovalRec: new ApprovalTab(),
});
let EntityV2Store = proxy({
  list: [],
  Cuslist: [],
  EntityRec: new EntityTypeV2(),
  getEntityById: (entityId) => {
    return EntityV2Store.list
  .filter((e) => e.entityId === entityId)
  .map((e) => `${e.entityName}${e.entityCountry ? ` - ${e.entityCountry}` : ''}`);
  },
});
let branchStore = proxy({
  list: [],
  BranchRec: new BranchType(),
});
let creditformStore = proxy({
  list: [],
  CreditformRec: new CreditFormType(),
});
let DBbranchStore = proxy({
  list: [],
  BranchRec: new BranchType(),
});

let creditAppStore = proxy({
  list: [],
  role:"",
  CreditAppRec: new CreditAappFormType(),
});
let customerStore = proxy({
  list: [],
  getCustomer4Email: (email) => {
    return customerStore.list
      .filter((e) => e.key === email)
      .map((e) => e.value);
  },
});
let quoteStore = proxy({
  listData: { quotesList: [], page: 0, quotesPerPage: 10, totalNumQuotes: 0 },
  setQuoteList: (data) => {
    quoteStore.listData = data;
  },
});
let quoteDataRec = proxy(new Quotation());

let contractsStore = proxy({
  navigateFromCharts: false,
  list: [],
  current: {},
  currentIndex: -1,
  lockedContract: "",
});
let schedulesStore = proxy({
  list: [],
});
let bookingsStore = proxy({
  list: [],
  current: {},
  initialize: () => {
    bookingsStore.current._id = "";
    bookingsStore.current.bookingDate = "";
    bookingsStore.current.status = "SUBMITTED";
    bookingsStore.current.contractNum = "";
    bookingsStore.current.branchId = "";
    bookingsStore.current.erpJobId = "";
    bookingsStore.current.contract = {};
    bookingsStore.current.schedule = "";
    bookingsStore.current.usermail = "";
    bookingsStore.current.firstName = "";
    bookingsStore.current.lastName = "";
    bookingsStore.current.phone = "";
    bookingsStore.current.emailid = "";
    bookingsStore.current.company = "";
    bookingsStore.current.address = "";
    bookingsStore.current.pin = "";
    bookingsStore.current.country = "";
    bookingsStore.current.incoTerms = "";
    bookingsStore.current.isfromAgragaEnquiry = ''
    bookingsStore.current.checkAgCustomer = ''
    bookingsStore.current.commodityType = "";
    bookingsStore.current.specialInstructions = "";
    bookingsStore.current.carrierMBL = "No";
    bookingsStore.current.msds_filename = "";
    bookingsStore.current.dgdecl_filename = "";
    bookingsStore.current.nondgdecl_filename = "";
    bookingsStore.current.iipcert_filename = "";
    bookingsStore.current.commercialInvoice_filename_list = [];
    bookingsStore.current.packingList_filename_list = [];
    bookingsStore.current.hasToShowDummySchedule = false;
    //bookingsStore.current.status = ''
  },
  currentBookingsListComponent: "list",
  listActiveTab: "Submitted Bookings",
  hasToShowRequestCancellation: false,
  requestCancellationReason: null,
});
let toEmailStore = proxy({ emails: [] });
let ccEmailStore = proxy({ emails: [] });
const tcStore = proxy({
  tc: TC,
});
const routerStore = proxy({
  agusersCurrentView: "list",
  entityCurrentView: "list",
  vehicleCurrentView: "list",
  driverCurrentView: "list",
  creditApplicationView: "list",
  branchCurrentView: "list",
  creditsCurrentView: "new",
  entityUserManageView: "list",
});

const modalStore = proxy({
  lostShowModal: false,
  invalidShowModal: false,
  invalidShowReason: "",
  invalidShowBooks: [],
  lostShowReason: "",
  lostShowBooks: [],
  checkingOrigin: false,
  checkingDestination: false,
  disablehaz: false,
  disableTemp: false,
  enablerwp: false,
  enableref: false,
  enabletsp: false,
  list: []
});

const enquiryState = proxy({
   sscope : "",
   mode : "LCL" ,
   cargoReadinessDate : "",
   origin : "",
   destination : ""
})

const enquiryFormState = proxy({
  form : null
});

const configStore = proxy({
  currentSelectedEntity: {
    gst: "",
    state : ""
  }, 
  currentSelectedBranch: {
    gst: "",
    state : ""
  },// current entity under which user is operating
  scheduleOrCarrierform: "schedule",
  displaySchedules: false,
  showEnquiries: true,
  currentURL: "/",
  showQoute : false,
  currentMainComponent: <Dashboard />,
  currentMainMenu: "Dashboard",
  addNewCustomerVisible: false,
  vanishingMessage: "",
  modalVisible: false,
  bookingModalVisible: false,
  enquiryModalVisible: false,
  quotationModalVisible: false,
  quotationModalVisible1: false,
  quotationModalVisible2: false,
  quotationModalVisible3: false,
  quotationModalVisible4: false,
  modalvisibledata: {},
  modalMessage: "",
  bookingModalNum: "",
  enquiryModalNum: "",
  ajvErrors: [],
  tc: [],
  setDecline: "",
  disableShipmentType: "",
  quotationRejectReason: "",
  setMyacticecompleted: "",
  setMyacttioncancelbtn: true,
  addressvalidation: true,
  chartSelectedEntity: "ALL",
  agragaEnquiryreason:"",
  buttondisable:false,
  setFullEnquiry:{},
  currenctGst:"",
  currentEntityName:"",
  acceptMail:false,
  checkedEmptyDropdown:false,
  updateUnitDropdown:"",
  setModalMyacticecompleted: (
    setMyacticecompleted,
    setMyacttioncancelbtn = true
  ) => {
    configStore.setMyacticecompleted = setMyacticecompleted;
    configStore.setDecline = "";
    configStore.tc = [];
    configStore.modalMessage = "";
    configStore.ajvErrors = [];
    configStore.modalVisible = true;
    configStore.setMyacttioncancelbtn = setMyacttioncancelbtn;
  },
  setModalDecline: (setDecline) => {
    configStore.setMyacticecompleted = "";
    configStore.setDecline = setDecline;
    configStore.tc = [];
    configStore.modalMessage = "";
    configStore.ajvErrors = [];
    configStore.modalVisible = true;
  },

  setModalMessage: (
    message,
    ajvErrors = [],
    autoClose = true,
    tc = [],
    setDecline = "",
    timeout = 3000
  ) => {
    if (["No Error", "Invalid Token"].indexOf(message) == -1) {
      configStore.setDecline = setDecline;
      configStore.setMyacticecompleted = "";
      configStore.tc = tc;
      configStore.modalMessage = message;
      configStore.ajvErrors = [];
      //console.log('INSIDE SETMODALMESSAGE >>>>>>>>>>>>>>>')
      //console.log(ajvErrors)
      if (ajvErrors && ajvErrors.length > 0) {
        ajvErrors.map((e) =>
          configStore.ajvErrors.push(
            `${e.message} - ${JSON.stringify(
              e.instancePath.length > 0 ? e.instancePath : e.params
            )}`
          )
        );
      }
      //console.log('instore=', configStore.ajvErrors)
      configStore.modalVisible = true;
      if (autoClose) {
        setTimeout(() => {
          configStore.modalVisible = false;
        }, timeout);
      }
    }
  },




  setBookingModalMessage: (bookingNum) => {
    configStore.bookingModalNum = bookingNum;
    configStore.bookingModalVisible = true;
  },
  setEnquiryModalMessage: (enquiryNum) => {
    configStore.enquiryModalNum = enquiryNum;
    configStore.enquiryModalVisible = true;
  },
  quoteEditMode: true,
  quoteListMode: true,
  // list, view, edit
  quoteCurrentView: "list",
  dtCurrentView: "list",
  quoteClientCurrentView: "list",
  homescreenCurrentView: "list",
  homescreenCurrentShipmentType: "LCL",
  homescreenCurrentShipmentScope: "",
  currencyList: [],
  getCurrencyList: () => [
    "",
    ...configStore.currencyList.map((e) => e.currency),
  ],
  getExchangeRate: (currency) =>
    configStore.currencyList.find((e) => e.currency === currency)?.exchangeRate,
  hasToShowAgWarn: false,
  entityCurrentView: "list",
});
const cargoStore = proxy({
  editCargoId: -1,
  newCargo: new CargoDetails(),
  editCargo: new CargoDetails(),
  cargoList: [],
  cargoemptyList: [],
  totals: {
    totNumPackages: 0,
    totTotalWeight: 0,
    totTotalVolume: 0,
    totChargeableWeight: 0,
  },
  resetTotals: () => {
    cargoStore.totals.totNumPackages = 0;
    cargoStore.totals.totTotalWeight = 0;
    cargoStore.totals.totTotalVolume = 0;
    cargoStore.totals.totChargeableWeight = 0;
  },
  calculateCargoTotals: () => {
    cargoStore.resetTotals();
    cargoStore.cargoList.forEach((e) => {
      cargoStore.totals.totNumPackages += parseInt(e.numPackages);
      cargoStore.totals.totTotalWeight += parseFloat(e.totalWeight);
      cargoStore.totals.totTotalVolume += parseFloat(e.totalVolume);
      // cargoStore.totals.totChargeableWeight += parseFloat(e.chargeableWeight)
      cargoStore.totals.totTotalWeight = Utils.R(
        cargoStore.totals.totTotalWeight
      );
      cargoStore.totals.totTotalVolume = Utils.R(
        cargoStore.totals.totTotalVolume
      );
      cargoStore.totals.totChargeableWeight = Math.ceil(
        Math.max(
          cargoStore.totals.totTotalWeight,
          cargoStore.totals.totTotalVolume
        )
      );
    });
  },
  resetNewCargo: () => {
    const newCargo = new CargoDetails();
    Object.keys(cargoStore.newCargo).forEach(
      (cargoKey) => (cargoStore.newCargo[cargoKey] = newCargo[cargoKey])
    );
  },
});

watch((get) => {
  get(cargoStore);
  if (
    quoteDataRec.isFromEnquiry &&
    quoteDataRec.status === "DRAFT" &&
    cargoStore.cargoList != undefined && cargoStore.cargoList.length > 0 &&
    Object.values(cargoStore.totals).every((value) => value === 0)
  ) {
    cargoStore.cargoList = cargoStore.cargoList.map(calcCargoDetails);
    // cargoStore.calculateCargoTotals();
    cargoStore.calculateCargoTotals();
  }
});


export const newDashboardState = proxy({
  selectedTransportMode : 0,
  isProfileSelected : false ,
  selectedTab : 0
});

export const filtersOptions = proxy({
  routing : {
    por : [],
    pol : [],
    pod : [],
    fpod : []
  }
})

export const filtersState = proxy({
   directOnly : false,
   routing : {
    por : [],
    pol : [],
    pod :  [],
    fpod : []
   },
   routes : [],
   carrier : [],
   services : [],
   filteredList : [],
   updateFilteredList : ()=>{
    const updatedList = [];
    
    let isAllEmpty = true;
      if(filtersState.routing.por.length>0){
        isAllEmpty = false;
         filtersState.routes.forEach((record)=>{
          let por = Utils.getCountry(record?.por,true);
           if(filtersState.routing.por.includes(por?.toLowerCase())){
            if(!updatedList.some((rec)=>rec.set===record.set)){
              updatedList.push(record);
             }
           }
         })
      } 

      if(filtersState.routing.pod.length>0){
        isAllEmpty = false;
        filtersState.routes.forEach((record)=>{
          let pod = Utils.getCountry(record?.pod,true);
          if(filtersState.routing.pod.includes(pod?.toLowerCase())){
             if(!updatedList.some((rec)=>rec.set===record.set)){
              updatedList.push(record);
             }
          }
        })
     }

     if(filtersState.routing.pol.length>0){
      isAllEmpty = false;
      filtersState.routes.forEach((record)=>{
        let pol = Utils.getCountry(record?.pol,true);
        if(filtersState.routing.pol.includes(pol?.toLowerCase())){
          if(!updatedList.some((rec)=>rec.set===record.set)){
            updatedList.push(record);
           }
        }
      })
   }

   if(filtersState.routing.fpod.length>0){
    isAllEmpty = false;
    filtersState.routes.forEach((record)=>{
      let fpod = Utils.getCountry(record?.fpod,true);
      if(filtersState.routing.fpod.includes(fpod?.toLowerCase())){
        if(!updatedList.some((rec)=>rec.set===record.set)){
          updatedList.push(record);
         }
      }
    })
 }

//  if(filtersState.carrier.length>0){
//   isAllEmpty = false;
//   filtersState.routes.forEach((record)=>{
//     if(filtersState.carrier.includes(record?.carrier?.toLowerCase())){
//        updatedList.push(record);
//     }
//   })
// }

// if(filtersState.services.length>0){
//   isAllEmpty = false;
//   filtersState.routes.forEach((record)=>{
//     if(filtersState.services.includes(record?.services?.toLowerCase())){
//        updatedList.push(record);
//     }
//   })
// }
if(isAllEmpty){
  filtersState.filteredList = [...filtersState.routes];
}else{
  filtersState.filteredList = updatedList
};
   },
   addFilterOption : (filter,subFilter,option)=>{
       if(filter==="routing"){
          filtersState.routing[subFilter].push(option?.toLowerCase());
       }else{
          filtersState[filter].push(option?.toLowerCase());
       }
       console.log("before updateing the list ",filtersState.routing)
       filtersState.updateFilteredList();
   },
   removeFilterOption : (filter,subFilter,option)=>{
       if(filter==="routing"){
        filtersState.routing[subFilter] = filtersState.routing[subFilter].filter((rec)=>rec.toLowerCase()!==option.toLowerCase())
       }else{
        filtersState[filter] = filtersState[filter].filter((rec)=>rec.toLowerCase()!==option.toLowerCase())
       }
       filtersState.updateFilteredList();
   }
})

export const breadCrumbStore = proxy({
  breadCrumbs : [],
  currentComponent : "",
  reset : ()=>{
    breadCrumbStore.breadCrumbs = [];
    breadCrumbStore.currentComponent = "";
    enquiryStore.initialize();
  },
  back : ()=>{
    if(breadCrumbStore.breadCrumbs.length===2){
      breadCrumbStore.breadCrumbs = [];
      enquiryStore.initialize();
     }else {
      breadCrumbStore.breadCrumbs.splice(breadCrumbStore.breadCrumbs.length-1);
      breadCrumbStore.currentComponent = breadCrumbStore.breadCrumbs[breadCrumbStore.breadCrumbs.length-1];
     }
  },
  addCrumb : (crumb)=>{
     breadCrumbStore.breadCrumbs.push(crumb);
     breadCrumbStore.currentComponent = crumb;
  },
  setBreadCrumbs : (crumbs,currentCrumb)=>{
     breadCrumbStore.breadCrumbs = crumbs;
     if(currentCrumb){
        breadCrumbStore.currentComponent = currentCrumb;
     }else{
      breadCrumbStore.currentComponent = crumbs[crumbs.length-1];
     }
  },
  setCurrentCrumb :(i)=>{
     if(i===0){
      breadCrumbStore.breadCrumbs = [];
      enquiryStore.initialize();
     }else if(i<breadCrumbStore.breadCrumbs.length-1){
      breadCrumbStore.breadCrumbs.splice(i+1);
      breadCrumbStore.currentComponent = breadCrumbStore.breadCrumbs[i];
     }
  }
})



const containerStore = proxy({
  editId: -1,
  newRec: new ContainerDetails(),
  editRec: new ContainerDetails(),
  list: [],
  resetNewRec: () => (containerStore.newRec = new ContainerDetails()),
});
const loginStore = proxy({
  isShowClientDashBoard : false,
  email: "",
  password: "",
  userRec: {
    userFound: false,
    email: "",
    firstName: "",
    lastName: "",
    status: "",
    aguserRec: {},
    companyList: [], //this is different from the entityList as part of aguserRec. will also contain entities where user is admin
  },
  isClient: () => {
    let ret = false;
    if (loginStore.userRec.aguserRec && loginStore.userRec.aguserRec.role) {
      ret = loginStore.userRec.aguserRec.role
        .toLowerCase()
        .startsWith("client");
    }
    return ret;
  },
  reinitialize: () => {
    loginStore.userRec = {
      userFound: false,
      email: "",
      firstName: "",
      lastName: "",
      status: "",
      aguserRec: {},
      companyList: [],
    };
  },
});

const airChargesStore = proxy({
  newRec: new AirQuoteCharges(),
  list: [],
  initialLength: 0,
  calculateChargesTotals: () => {
    try {
      airChargesStore.list.map((e, i) => {
        if (
          (e.perKg > 0 || e.minimum > 0 || e.perAWB > 0) &&
          e?.currency === ""
        )
          throw new Error("Please select currency type.");
        let erate = configStore.getExchangeRate(e.currency);
        if (!erate || erate === 0) erate = 1.0;
        erate = 1.0; // nullifying conversion for per row totals
        if (!e.perKg) e.perKg = 0;
        if (!e.minimum) e.minimum = 0;
        if (!e.perAWB) e.perAWB = 0;
        let tots = cargoStore.totals;
        let val = Number(e.perKg) * Number(tots.totChargeableWeight);
        let temp = Number(e.minimum);
        if (temp > val) val = temp;
        e.total = Utils.R((Number(val) + Number(e.perAWB)) * erate);
      });
    } catch (error) {
      configStore.setModalMessage(error?.message || error);
    }
  },
});
const chargesStore = proxy({
  colNames: {
    twoSpan: "Per Unit",
    twoSpan1: "Per W/M",
    twoSpan2: "Minimum",
    perShipment: "Per Shipment",
  },
  newRec: new QuoteCharges(),
  list: [],
  initialLength: 0,
});
const chargesStore1 = proxy({
  newRec: new QuoteCharges1(),
  list: [],
  initialLength: 0,
  calculateLCLChargesTotals: () => {
    let voldis = 0
    try {
      chargesStore1.list.map((e, i) => {
        if (
          (e.perCBM > 0 ||
            e.perTon > 0 ||
            e.minimum > 0 ||
            e.maximum > 0 ||
            e.perShipment > 0) &&
          e?.currency === ""
        )
          throw new Error("Please select currency type.");
        let erate = configStore.getExchangeRate(e.currency);
        if (!erate || erate === 0) erate = 1.0;
        erate = 1.0; // nullifying the rate.
        if (!e.perCBM) e.perCBM = 0;
        if (!e.perTon) e.perTon = 0;
        if (!e.minimum) e.minimum = 0;
        if (!e.maximum) e.maximum = 0;
        if (!e.perShipment) e.perShipment = 0;

        console.log('\n\n\n////////////////////////////\nAbout to recalculate', e.chargeDescription)
        
        let tots = cargoStore.totals;
        console.log(e.perCBM, e.perTon, tots.totTotalVolume, tots.totTotalWeight)
        let val = Number(e.perCBM) * Number(tots.totTotalVolume);
        let temp = (Number(e.perTon) * Number(tots.totTotalWeight)) / 1000;
        if (temp > val) val = temp;
        temp = Number(e.minimum);
        if (temp > val) val = temp;
        temp = Number(e.maximum);
        if (temp > 0 && temp < val) val = temp;

        e.total = Utils.R((Number(val) + Number(e.perShipment)) * erate);
        console.log('Charge total=', e.total)

        if (e.chargeDescription == 'Ocean Freight') {
          console.log('got to OF')
          if (e.perCBM < 0 || e.perTon < 0 || e.minimum < 0 || e.maximum < 0) {
            console.log('something is < 0')
            let val = Number(e.perCBM) * Number(tots.totTotalVolume);
            let temp = (Number(e.perTon) * Number(tots.totTotalWeight)) / 1000;
            if (temp < val) val = temp;
            temp = Number(e.minimum);
            if (temp < val) val = temp;
            temp = Number(e.maximum);
            if (temp != 0 && temp > val) val = temp;
            e.total = Utils.R((Number(val) + Number(e.perShipment)) * erate);
            console.log('the revised e.tota =', e.total)
            if (e.total <= 0) {
              e.currency = 'USD'
              let oldtotal = e.total
              let tons = tots.totTotalWeight / 1000
              e.total = tots.totTotalVolume > tons ? tots.totTotalVolume : tons
              if (e.total < 1) e.total = 1
              console.log('\n\n\n*** e.total=', e.total, e.currency)
              voldis = oldtotal - e.total
            }
          }
        }
      })
      chargesStore1.list = chargesStore1.list.filter(e => e.chargeDescription != 'Volume Discount')
      if (voldis != 0) {
        chargesStore1.list.push({
          chargeDescription: 'Volume Discount',
          onaccount: 'On Your Account',
          currency: 'USD',
          perShipment: voldis,
          total: voldis
        })
      }
    } catch (error) {
      configStore.setModalMessage(error?.message || error);
    }
  },
  calculateChargesTotals: () => {
    try {
      chargesStore1.list.map((e, i) => {
        if (
          (e.perCBM > 0 ||
            e.perTon > 0 ||
            e.minimum > 0 ||
            e.maximum > 0 ||
            e.perShipment > 0) &&
          e?.currency === ""
        )
          throw new Error("Please select currency type.");
        let erate = configStore.getExchangeRate(e.currency);
        if (!erate || erate === 0) erate = 1.0;
        erate = 1.0; // nullifying the rate.
        if (!e.perCBM) e.perCBM = 0;
        if (!e.perTon) e.perTon = 0;
        if (!e.minimum) e.minimum = 0;
        if (!e.maximum) e.maximum = 0;
        if (!e.perShipment) e.perShipment = 0;

        let tots = cargoStore.totals;
        let val = Number(e.perCBM) * Number(tots.totTotalVolume);
        let temp = (Number(e.perTon) * Number(tots.totTotalWeight)) / 1000;
        if (temp > val) val = temp;
        temp = Number(e.minimum);
        if (temp > val) val = temp;
        temp = Number(e.maximum);
        if (temp > 0 && temp < val) val = temp;
        e.total = Utils.R((Number(val) + Number(e.perShipment)) * erate);
      })
    } catch (error) {
      configStore.setModalMessage(error?.message || error);
    }
  },
});

const fclChargesStore = proxy({
  newRec: new FCLQuoteCharges(),
  list: [],
  initialLength: 0,
  calculateChargesTotals: () => {
    try {
      fclChargesStore.list.map((e, i) => {
        if (
          Object.entries(e)
            .filter(
              ([key, value]) => !isNaN(value) && key.toLowerCase() !== "id"
            )
            .some(([key, value]) => value > 0) &&
          e?.currency === ""
        )
          throw new Error("Please select currency type.");
        let erate = configStore.getExchangeRate(e.currency);
        if (!erate || erate === 0) erate = 1.0;
        erate = 1.0; // nullifying the per row conversion.
        containerStore.list.map((e1) => {
          let c = e1.containerType;
          let perwmField = c + "perWM";
          let pershipmentField = c + "perShipment";
          let totalField = c + "total";
          if (!e[perwmField]) e[perwmField] = 0;
          if (!e[pershipmentField]) e[pershipmentField] = 0;
          if (!e[totalField]) e[totalField] = 0;
          let perwm = Number(e[perwmField]);
          let pership = Number(e[pershipmentField]);
          let num = Number(e1.numContainers) || 0;
          e[totalField] = Utils.R((perwm * num + pership) * erate);
        });
      });
    } catch (error) {
      configStore.setModalMessage(error?.message || error);
    }
  },

  defaultValueCalc: () => { // Initial value calculation for validation
    fclChargesStore.list.map((e) => {
      containerStore.list.map((e1) => {
        let c = e1.containerType;
        let perwmField = c + "perWM";
        let pershipmentField = c + "perShipment";
        let totalField = c + "total";
        if (!e[perwmField]) e[perwmField] = 0;
        if (!e[pershipmentField]) e[pershipmentField] = 0;
        if (!e[totalField]) e[totalField] = 0;
      });
    })
  }
});

watch((get) => {
  get(chargesStore1);
  get(fclChargesStore);
  get(airChargesStore);
  if (
    chargesStore1.list.length > 0 ||
    fclChargesStore.list.length > 0 ||
    airChargesStore.list.length > 0
  ) {
    if (
      !chargesValidationStore.isSaveBtnClicked &&
      quoteDataRec.quotationNum === ""
    )
      chargesValidationStore.isNew = true;
    chargesValidationStore.isSaveBtnClicked = false;
  }
});

const initiaizeCommonState = () => {
  quoteDataRec.intialize();
  toEmailStore.emails = [];
  ccEmailStore.emails = [];

  cargoStore.editCargoId = -1;
  cargoStore.newCargo = new CargoDetails();
  cargoStore.editCargo = new CargoDetails();
  cargoStore.cargoList = [];
  cargoStore.totals = {
    totNumPackages: 0,
    totTotalWeight: 0,
    totTotalVolume: 0,
  };

  containerStore.editId = -1;
  containerStore.newRec = new ContainerDetails();
  containerStore.editRec = new ContainerDetails();
  containerStore.list = [];

  chargesStore.newRec = new QuoteCharges();
  chargesStore.list = [];
  chargesStore.initialLength = 0;

  chargesStore1.newRec = new QuoteCharges();
  chargesStore1.list = [];
  chargesStore1.initialLength = 0;

  airChargesStore.newRec = new AirQuoteCharges();
  airChargesStore.list = [];
  airChargesStore.initialLength = 0;

  fclChargesStore.newRec = new FCLQuoteCharges();
  fclChargesStore.list = [];
  fclChargesStore.initialLength = 0;

  QuotationValidationStore.reset();
};
const setCommonState = (quote) => {
  initiaizeCommonState();
  if (quote.status == "DECLINED") {
    quote.quoteData["quoteStatus"] = quote.status;
    quote.quoteData["decline_reason"] = quote.decline_reason;
    quote.quoteData["tsDeclineBy"] = quote.tsDeclineBy;
    quote.quoteData["tsDeclineOn"] = quote.tsDeclineOn;
  }
  if (quote.status == "LOST") {
    quote.quoteData["quoteStatus"] = quote.status;
    quote.quoteData["LostReason"] = quote.LostReason;
    quote.quoteData["tsLostBy"] = quote.tsLostBy;
    quote.quoteData["tsLostOn"] = quote.tsLostOn;
  }
  if (quote.status == "INVALID") {
    quote.quoteData["quoteStatus"] = quote.status;
    quote.quoteData["InvalidReason"] = quote.InvalidReason;
    quote.quoteData["tsInvalidBy"] = quote.tsInvalidBy;
    quote.quoteData["tsInvalidOn"] = quote.tsInvalidOn;
  }
  if (quote.status == "WON") {
    quote.quoteData["quoteStatus"] = quote.status;
    quote.quoteData["tsWonBy"] = quote.tsWonBy;
    quote.quoteData["tsWonOn"] = quote.tsWonOn;
  }
  quoteDataRec.set(quote.quoteData);

  cargoStore.cargoList = quote.cargoList ? quote.cargoList : [];
  cargoStore.totals = quote.cargoTotals
    ? quote.cargoTotals
    : {
      totNumPackages: 0,
      totTotalWeight: 0,
      totTotalVolume: 0,
    };

  containerStore.list = quote.containerList;

  if (quote.quoteData.shipmentType.indexOf("FCL") >= 0) {
    fclChargesStore.list = quote.chargesList;
  } else if (quote.quoteData.shipmentType.indexOf("Air") >= 0) {
    airChargesStore.list = quote.chargesList;
  } else {
    chargesStore1.list = quote.chargesList;
  }
};

const multiSelectListStore = proxy({
  selectList: {},
  resetSelectList: () => (multiSelectListStore.selectList = {}),
});

const dataListStore = proxy({
  FCLCarrierList: [],
  P44CarrierList: [],
});

const quotatationImportStore = proxy({
  isFormVisible: false,
  selectedShipmentType: "",
  entity: "",
  quotationSheetFile: null,
  reset: () => {
    quotatationImportStore.quotationSheetFile = null;
    quotatationImportStore.isFormVisible = false;
    quotatationImportStore.selectedShipmentType = "";
    quotatationImportStore.entity = "";
  },
});

const enquiriesValidationStore = proxy({
  origin: false,
  destination: false,
  cargoReadinessDate: false
});

  // devtools(proxy([{
  //   aguserStore,
  //   entityStore,
  //   configStore,
  //   vehicleStore,
  //   DriverStore,
  //   branchStore,
  //   DBbranchStore,
  //   creditAppStore,
  //   customerStore,
  //   toEmailStore,
  //   ccEmailStore,
  //   quoteStore,
  //   quoteDataRec,
  //   contractsStore,
  //   cargoStore,
  //   loginStore,
  //   containerStore,
  //   chargesStore,
  //   chargesStore1,
  //   airChargesStore,
  //   fclChargesStore,
  //   creditformStore,
  //   initiaizeCommonState,
  //   setCommonState,
  //   routerStore,
  //   bookingsStore,
  //   schedulesStore,
  //   modalStore,
  //   multiSelectListStore,
  //   dataListStore,
  //   quotatationImportStore,
  //   EntityV2Store,
  //   enquiriesValidationStore,}]),{ name: 'Store', enabled: true })

  
export {
  aguserStore,
  cususerStore,
  entityStore,
  configStore,
  vehicleStore,
  DriverStore,
  branchStore,
  DBbranchStore,
  creditAppStore,
  customerStore,
  toEmailStore,
  ccEmailStore,
  quoteStore,
  quoteDataRec,
  contractsStore,
  cargoStore,
  loginStore,
  containerStore,
  chargesStore,
  chargesStore1,
  airChargesStore,
  fclChargesStore,
  creditformStore,
  initiaizeCommonState,
  setCommonState,
  routerStore,
  bookingsStore,
  schedulesStore,
  modalStore,
  multiSelectListStore,
  dataListStore,
  quotatationImportStore,
  EntityV2Store,
  enquiriesValidationStore,
  enquiryFormState,
  enquiryState,
  ApprovalTabStore
};
