import { proxy, useSnapshot } from "valtio"
import { useState, useEffect, useRef } from "react"
import {
    aguserStore, configStore, entityStore, loginStore
} from '../state/CommonState'
import EntityApi from "../state/entity-api"
import { $c, AgInput } from "../components/AgComponents"
import MyProfileEntityDocs from "./my-profile-entity-kyc"
import { EntityType } from "../state/Types"
import { useGlobalState } from '../GlobalState'
import InviteUser from "./invite-user"

function AddEntity(props) {
    let entity = proxy({ entityName: '', gst: '' })
    useSnapshot(aguserStore)
    useSnapshot(entityStore)
    useSnapshot(loginStore)
    const aguser = aguserStore.aguserRec
    const { changeCurrentMenu } = useGlobalState()
    return (
        <div className="panel" style={{
            paddingLeft: '10px',
            paddingBottom: '3px', border: '1px solid lightgrey', backgroundColor: '#efefff'
        }}>
            <div className="columns">
                <$c n='5'>
                    <AgInput label='Entity name' record={entity} name='entityName' />
                </$c>
                <$c n='5'>
                    <AgInput label='GST' record={entity} name='gst' />
                </$c>
                <$c n='2' style={{display:'flex', paddingBottom: '0px', justifyContent: 'flex-end' }}>
                    <button type="button" className="button is-small islink"
                        style={{ marginTop: '0.9rem' }}
                        onClick={(e) => {
                            console.log('onsubmit=', entity.entityName, entity.gst)
                            let newentity = new EntityType()
                            newentity.entityName = entity.entityName
                            newentity.gst = entity.gst
                            newentity.admin = loginStore.email
                            EntityApi.createEntityForUser(loginStore.email, newentity, (data) => {
                                let d = JSON.parse(localStorage.getItem('ag_userrec'))
                                d.companyList = data.companyList
                                d = JSON.stringify(d)
                                localStorage.setItem('ag_userrec',d)
                                entityStore.list = data.companyList
                                aguser.entityList = data.companyList
                                loginStore.userRec.companyList = data.companyList
                                // changeCurrentMenu('Dashboard')
                                // loginStore.reinitialize()
                                // localStorage.removeItem('ag_email')
                                // localStorage.removeItem('ag_userrec')
                                props.resetState(false)
                            })
                        }}>
                        Submit
                    </button>
                    <button className="button is-small is-danger"
                        style={{ marginTop: '0.9rem', marginRight: '0.5rem' }}
                        onClick={() => props.resetState(false)}>
                        <span className="icon is-small">
                            <i className="fas fa-xmark"></i>
                        </span>
                    </button>
                </$c>
                <$c n='1'></$c>
            </div>
        </div>
    )
}



export default function MyProfileEntity(props) {
    const [showKyc, setShowKyc] = useState('')
    const [inviteGst, setInviteGst] = useState('')
    const [showNewEntity, setShowNewentity] = useState(false)
    let [space, setSpace] = useState('')
    let [newEntity, setNewEntity] = useState('')
    let [newRole, setNewRole] = useState('CLIENT USER')
    useSnapshot(aguserStore)
    useSnapshot(entityStore)
    useSnapshot(loginStore)
    const aguser = aguserStore.aguserRec
    let prevGst = ''
    let entityElem = {}
    const getEntityForGst = (gst) => {
        if (gst === prevGst) return entityElem
        let data = entityStore.list.filter(e => e.gst === gst)
        if (data.length > 0) entityElem = data[0]
        else entityElem = {}
        prevGst = gst
        return entityElem
    }

   

    console.log('aguser.entitylist =', aguser)
    aguser.entityList?.map((e, i) => console.log(e.gst))
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>{space}
            <hr />
            <div className="table-container " style={{ width: '90%', fontSize: '0.85rem' }}>
                <table className="table  is-striped is-narrow is-hoverable "
                    style={{ width: '90%' }}>
                    <thead>
                        <tr className="tour5" style={{ borderTop: '2px solid lightgrey', borderBottom: '2px solid lightgrey' }}>
                            <th colSpan={4} style={{ borderRight: 'none' }}>
                                <div>
                                    <h5 className="title is-5"
                                        style={{ float: 'left', marginBottom: '2px', color: '#2c358a' }}>
                                        Company Details
                                    </h5>
                                </div>
                            </th>
                            <th style={{ borderLeft: 'none', float: 'right' }}>
                                {/* <button
                                    className="button is-rounded islink is-small"

                                    onClick={() => {
                                        setShowNewentity(!showNewEntity)
                                    }}>
                                    Add Entity
                                </button> */}

                            </th>   
                        </tr>
                        {/* {
                            (showNewEntity) ?
                                <tr>
                                    <td colSpan={4}>
                                        <AddEntity resetState={setShowNewentity} />
                                    </td>
                                </tr> :
                                <></>
                        } */}
                        <tr>
                            <th>Entity Name</th>
                            {/* <th>PAN</th> */}
                            <th>GST</th>
                            <th>PAN</th>
                            <th>Role</th>
                            <th>Designation</th>
                        </tr>
                    </thead>
                    <tbody>
        
                        
                         {
                            props.data.mainEntityList?.map((e, i) => {
                                return (
                                    <>
                                        <tr style={{ borderBottom: '1px solid lightgrey' }} key={i}>
                                            <td>{e.entityName}</td>
                                            <td style={{ borderRight: 'none' }}>{e.gstList.map((r,i) => {
                                                return <p>{r.gst} - {r.state?.split("/")[0]} </p>
                                            })}</td>
                                            <td>{e.entityIdentifier}</td>
                                            <td>{e.role}</td>
                                            <td>{e.designation}</td>
                                            {/* <td title={getEntityForGst(e.gst).address}>
                                                {getEntityForGst(e.gst).state}
                                                <span className="icon">
                                                    <i className="fas fa-circle-info"></i>
                                                </span>
                                            </td> */}
                                            {/* {
                                                (getEntityForGst(e.gst).status !== 'TEMPORARY' && e.role === 'CLIENT ADMIN') ?
                                                    <td style={{ width: 'auto', float: 'right', borderLeft: 'none' }}>
                                                        {
                                                            (inviteGst === e.gst) ?
                                                                <InviteUser gst={e.gst} resetState={setInviteGst} />
                                                                :
                                                                <button className="button islink is-rounded is-small"
                                                                    onClick={() => {
                                                                        console.log('Setting new entity = ', e.gst)
                                                                        setInviteGst(e.gst)
                                                                    }}>
                                                                    Invite User
                                                                </button>
                                                        }
                                                    </td> : <td></td>
                                            } */}
                                        </tr>
                                        <tr style={{ padding: '0px' }}>
                                            <td style={{ textAlign: 'center', padding: '0px' }} colSpan={5}>
                                                {
                                                    (showKyc === e.entityId) ?
                                                        <button className='button is-warning is-small is-fullwidth'
                                                            style={{ padding: '0px' }}
                                                            onClick={() => setShowKyc('')}>
                                                            Hide KYC Documents
                                                            <span className="icon" style={{ marginLeft: '5px' }}>
                                                                <i className="fas fa-sort-up"></i>
                                                            </span>
                                                        </button> :
                                                        <button className='button islink is-small is-fullwidth'
                                                            style={{ padding: '0px' }}
                                                            onClick={() => setShowKyc(e.entityId)}>
                                                            Show KYC Documents
                                                            <span className="icon" style={{ marginLeft: '5px' }}>
                                                                <i className="fas fa-sort-down"></i>
                                                            </span>
                                                        </button>
                                                }
                                            </td>
                                        </tr>
                                        {console.log('checkshowkyc',showKyc , e.entityId)}
                                        <tr>
                                            {
                                                (showKyc === e.entityId) ?
                                                    <td colSpan={5}>
                                                        <MyProfileEntityDocs entity={e} />
                                                    </td> : <></>
                                            }
                                        </tr>
                                    </>
                                )
                            }
                            )
                        }
                        {
                            props.data.entityList?.map((e, i) => {
                                return (
                                    <>
                                        <tr style={{ borderBottom: '1px solid lightgrey' }} key={i}>
                                            <td>{e.entityName}</td>
                                            <td style={{ borderRight: 'none' }}>{e.gstList.map((r,i) => {
                                                return <p>{r.gst} - {r.state?.split("/")[0]} </p>
                                            })}</td>
                                            <td>{e.entityIdentifier}</td>
                                            <td>{e.role}</td>
                                            <td>{e.designation}</td>
                                            {/* <td title={getEntityForGst(e.gst).address}>
                                                {getEntityForGst(e.gst).state}
                                                <span className="icon">
                                                    <i className="fas fa-circle-info"></i>
                                                </span>
                                            </td> */}
                                            {/* {
                                                (getEntityForGst(e.gst).status !== 'TEMPORARY' && e.role === 'CLIENT ADMIN') ?
                                                    <td style={{ width: 'auto', float: 'right', borderLeft: 'none' }}>
                                                        {
                                                            (inviteGst === e.gst) ?
                                                                <InviteUser gst={e.gst} resetState={setInviteGst} />
                                                                :
                                                                <button className="button islink is-rounded is-small"
                                                                    onClick={() => {
                                                                        console.log('Setting new entity = ', e.gst)
                                                                        setInviteGst(e.gst)
                                                                    }}>
                                                                    Invite User
                                                                </button>
                                                        }
                                                    </td> : <td></td>
                                            } */}
                                        </tr>
                                        <tr style={{ padding: '0px' }}>
                                            <td style={{ textAlign: 'center', padding: '0px' }} colSpan={5}>
                                                {
                                                    (showKyc === e.entityId) ?
                                                        <button className='button is-warning is-small is-fullwidth'
                                                            style={{ padding: '0px' }}
                                                            onClick={() => setShowKyc('')}>
                                                            Hide KYC Documents
                                                            <span className="icon" style={{ marginLeft: '5px' }}>
                                                                <i className="fas fa-sort-up"></i>
                                                            </span>
                                                        </button> :
                                                        <button className='button islink is-small is-fullwidth'
                                                            style={{ padding: '0px' }}
                                                            onClick={() => setShowKyc(e.entityId)}>
                                                            Show KYC Documents
                                                            <span className="icon" style={{ marginLeft: '5px' }}>
                                                                <i className="fas fa-sort-down"></i>
                                                            </span>
                                                        </button>
                                                }
                                            </td>
                                        </tr>
                                        {console.log('checkshowkyc',showKyc , e.entityId)}
                                        <tr>
                                            {
                                                (showKyc === e.entityId) ?
                                                    <td colSpan={5}>
                                                        <MyProfileEntityDocs entity={e} />
                                                    </td> : <></>
                                            }
                                        </tr>
                                    </>
                                )
                            }
                            )
                        }

                    </tbody>
                </table>
            </div>
        </div>
    )
}
